import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AgentCard from '../components/AgentCard';
import agentsData from '../agents.json';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ConsultingLandingPage = () => {
  const [agents, setAgents] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    const updatedAgents = [
      ...agentsData,
      {
        id: 'das140',
        name: 'DAS-140 Form Generator',
        description:
          'Automate DAS-140 form generation and submission for construction compliance.',
        image: '/images/das140-agent.jpg',
        link: '/das140-agent',
      },
    ];
    setAgents(updatedAgents);
  }, []);

  const testimonials = [
    {
      name: 'John Doe',
      text: 'Construct Regulate transformed our compliance process, saving us time and money.',
    },
    {
      name: 'Jane Smith',
      text: 'The automation solutions provided by Construct Regulate are top-notch!',
    },
    // Add more testimonials as needed
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const handleConsultationClick = () => {
    setIsFormVisible(true);
    document.getElementById('consultation-form').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <Helmet>
        <title>AI-Powered Automation Consulting | Construct Regulate</title>
        <meta name="description" content="Unlock business efficiency with AI-powered automation consulting for the construction industry. Schedule a free consultation today." />
        <meta name="keywords" content="AI automation, construction consulting, business efficiency, process discovery, AI integration, compliance automation" />
        <meta property="og:title" content="AI-Powered Automation Consulting | Construct Regulate" />
        <meta property="og:description" content="Transform your construction business with AI-powered automation. Discover how our consulting services can revolutionize your operations." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/agents-marketplace" />
        <meta property="og:image" content="https://constructregulate.com/images/consulting-og-image.jpg" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative h-[75vh] bg-gradient-to-r from-blue-500 to-purple-600 overflow-hidden" data-aos="fade-up">
        <img
          src="/images/agent.jpg"
          alt="AI Agents in Action"
          className="absolute inset-0 w-full h-full object-cover mix-blend-overlay"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white text-center px-8 space-y-6">
          <h1 className="text-6xl font-extrabold">
            Unlock Business Efficiency with AI-Powered Automation
          </h1>
          <p className="text-lg max-w-2xl">
            Discover how automation can revolutionize your construction business without disrupting existing workflows.
          </p>
          <button onClick={handleConsultationClick} className="bg-purple-700 text-white px-10 py-3 rounded-full text-xl font-medium hover:bg-purple-800 transition-all duration-300">
            Schedule a Free Consultation
          </button>
        </div>
      </div>

      {/* Core Services Section */}
      <div className="container mx-auto px-6 py-16" data-aos="fade-up">
        <h2 className="text-4xl font-bold text-center mb-8">Our Consulting Services</h2>
        <p className="text-center text-xl mb-12">
          We specialize in identifying and integrating AI-powered automation into your operations, helping you save time, cut costs, and reduce manual errors.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          <div className="p-8 shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-4">Process Discovery & Assessment</h3>
            <p className="text-gray-700 mb-4">
              Our experts collaborate with your team to identify processes that can benefit from automation. We prioritize tasks based on impact, complexity, and return on investment.
            </p>
            <Link to="/process-discovery" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg inline-block">
              Learn More
            </Link>
          </div>

          <div className="p-8 shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-4">AI Integration & Automation Setup</h3>
            <p className="text-gray-700 mb-4">
              We integrate AI-driven automation seamlessly into your current systems, minimizing disruption while maximizing productivity.
            </p>
            <Link to="/ai-integration" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg inline-block">
              Explore Integration
            </Link>
          </div>

          <div className="p-8 shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-4">Compliance & Workflow Automation</h3>
            <p className="text-gray-700 mb-4">
              Automate compliance tasks like DAS-140 filing and safety monitoring, ensuring smooth operations and avoiding penalties.
            </p>
            <Link to="/compliance-automation" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg inline-block">
              See Compliance Automation
            </Link>
          </div>

          <div className="p-8 shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-4">Ongoing Support & Optimization</h3>
            <p className="text-gray-700 mb-4">
              Our team provides continuous support to ensure your automation systems evolve with your business needs.
            </p>
            <Link to="/ongoing-support" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg inline-block">
              Get Ongoing Support
            </Link>
          </div>

          <div className="p-8 shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-4">Compliance Dashboard</h3>
            <p className="text-gray-700 mb-4">
              Our comprehensive compliance dashboard provides real-time insights into your project's regulatory adherence, labor compliance, and safety standards, helping you stay ahead of potential issues.
            </p>
            <Link to="/services/compliance-dashboard" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg inline-block">
              View Compliance Dashboard
            </Link>
          </div>

          <div className="p-8 shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-4">Financial Management Dashboard</h3>
            <p className="text-gray-700 mb-4">
              Our financial dashboard offers a centralized view of your project's financial health, including budget tracking, expense management, and predictive analytics for informed decision-making.
            </p>
            <Link to="/services/financial-dashboard" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg inline-block">
              Explore Financial Dashboard
            </Link>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="container mx-auto px-6 py-16" data-aos="fade-up">
        <h2 className="text-4xl font-bold text-center mb-8">What Our Clients Say</h2>
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="text-center">
              <p className="text-xl italic mb-4">"{testimonial.text}"</p>
              <p className="text-lg font-semibold">- {testimonial.name}</p>
            </div>
          ))}
        </Slider>
      </div>

      {/* Demo Agents Section */}
      <div className="container mx-auto px-6 py-16" data-aos="fade-up">
        <h2 className="text-4xl font-bold text-center mb-8">See Automation in Action</h2>
        <p className="text-center text-xl mb-12">
          These AI agents are just a glimpse of what automation can achieve. Imagine these capabilities customized for your unique operations.
        </p>

        <div className="overflow-x-auto pb-10 mb-16">
          <div className="flex space-x-8">
            {agents.map((agent) => (
              <AgentCard key={agent.id} agent={agent} />
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white py-16" data-aos="fade-up">
        <div className="container mx-auto text-center space-y-6">
          <h2 className="text-5xl font-bold">Ready to Automate Your Business?</h2>
          <p className="text-xl">
            Book a free consultation today, and let’s explore how automation can elevate your operations.
          </p>
          <button onClick={handleConsultationClick} className="bg-white text-purple-700 px-10 py-3 rounded-full text-xl font-medium hover:bg-gray-200 transition-all duration-300">
            Book a Consultation
          </button>
        </div>
      </div>

      {/* Lead Capture Form Section */}
      {isFormVisible && (
        <div id="consultation-form" className="container mx-auto px-6 py-16" data-aos="fade-up">
          <h2 className="text-4xl font-bold text-center mb-8">Request a Consultation</h2>
          <form className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Name
              </label>
              <input
                id="name"
                type="text"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ConsultingLandingPage;
