import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// Error handling for extension-related errors
window.addEventListener('error', (event) => {
  if (event.filename.includes('chrome-extension://')) {
    event.preventDefault(); // Prevent the error from appearing in the console
    console.log('Suppressed extension-related error:', event.error);
  }
});

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
