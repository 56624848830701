import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { ThemeContext } from '../contexts/ThemeContext';
import Button from '../components/Button';
import { motion, AnimatePresence } from 'framer-motion';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { API_BASE_URL } from '../config';
import DAS140Agent from './DAS140Agent';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

function AIResumeAgent() {
  const { isDarkMode, currentStyle } = useContext(ThemeContext);
  const isConstructFuture = currentStyle === 'Construct Future';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [jobDescription, setJobDescription] = useState('');
  const [linkedinProfile, setLinkedinProfile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState('timeComparison');
  const [showSuccessCard, setShowSuccessCard] = useState(false);

  const timeComparisonData = {
    labels: ['Job Analysis', 'Resume Creation', 'Cover Letter'],
    datasets: [
      {
        label: 'Manual Time (minutes)',
        data: [45, 120, 60],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'AI Time (minutes)',
        data: [1, 2, 2],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const timeSavedData = {
    labels: ['Time Saved', 'AI Processing Time'],
    datasets: [
      {
        data: [220, 5],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 159, 64, 0.6)'],
      },
    ],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('resume', resume);
    formData.append('jobDescription', jobDescription);
    formData.append('linkedinProfile', linkedinProfile);

    try {
      const response = await axios.post('https://n8n.loamicbuilders.com/webhook/newresume', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data) {
        setSubmitSuccess(true);
        setShowSuccessCard(true);
        clearForm();
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show error message to user)
    }

    setIsSubmitting(false);
  };

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setResume(null);
    setJobDescription('');
    setLinkedinProfile(null);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100 text-gray-800'}`}>
      <Helmet>
        <title>AI-Powered Resume Revolution | Construct Regulate</title>
        <meta name="description" content="Transform your career with our AI-powered resume and cover letter generator. Tailored for construction professionals, our tool optimizes your application for ATS systems." />
        <meta name="keywords" content="AI resume, cover letter generator, construction career, job application optimization, ATS-friendly resumes" />
        <meta property="og:title" content="AI-Powered Resume Revolution | Construct Regulate" />
        <meta property="og:description" content="Elevate your job application process with cutting-edge AI. Create tailored resumes and cover letters that stand out in the construction industry." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/ai-resume-agent" />
        <meta property="og:image" content="https://constructregulate.com/images/ai-resume-og-image.jpg" />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <motion.header 
          className="text-center mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className={`text-4xl md:text-5xl font-bold mb-4 ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
            AI-Powered Resume Revolution
          </h1>
          <h2 className="text-2xl md:text-3xl mb-6">Transform Your Career with Precision AI</h2>
          <p className="text-lg max-w-3xl mx-auto">
            Harness the power of advanced AI to craft the perfect resume and cover letter. 
            Elevate your job application process with cutting-edge automation and expert insights.
          </p>
        </motion.header>

        <section className="mb-12">
          <h2 className={`text-3xl md:text-4xl font-bold mb-8 text-center ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
            Meet Your AI Dream Team
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            <AgentCard 
              title="Job Insight Analyzer"
              icon="📊"
              description="AI-driven analysis of job listings for tailored resumes."
              features={[
                "Skill and qualification extraction",
                "ATS keyword optimization",
                "Tailoring recommendations"
              ]}
              timeSaved="44 minutes"
            />
            <AgentCard 
              title="Resume Architect"
              icon="🏗️"
              description="Advanced AI model for professional resume generation."
              features={[
                "Experience alignment",
                "Skill highlighting",
                "ATS-friendly formatting"
              ]}
              timeSaved="118 minutes"
            />
            <AgentCard 
              title="Cover Letter Composer"
              icon="✍️"
              description="AI-crafted cover letters that complement your resume."
              features={[
                "Personalized content creation",
                "Qualification emphasis",
                "Tone and enthusiasm matching"
              ]}
              timeSaved="58 minutes"
            />
          </div>
        </section>

        <section className="mb-12">
          <h2 className={`text-3xl md:text-4xl font-bold mb-8 text-center ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
            How It Works
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {['Upload', 'Analyze', 'Generate', 'Deliver'].map((step, index) => (
              <motion.div
                key={step}
                className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg text-center`}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="text-2xl font-bold mb-2">{index + 1}</div>
                <h3 className="text-xl font-semibold mb-2">{step}</h3>
                <p className="text-sm">{getStepDescription(step)}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <motion.section 
          className="mb-12"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className={`text-3xl md:text-4xl font-bold mb-8 text-center ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
            Revolutionize Your Application Process
          </h2>
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-xl p-6`}>
            <form onSubmit={handleSubmit} className="grid md:grid-cols-2 gap-4">
              <div className="space-y-4">
                <InputField
                  label="First Name"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <InputField
                  label="Last Name"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <InputField
                  label="Email Address"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-4">
                <FileUpload
                  label="Upload Resume (PDF)"
                  id="resume"
                  accept=".pdf"
                  onChange={(e) => setResume(e.target.files[0])}
                  required
                />
                <TextArea
                  label="Job Description"
                  id="jobDescription"
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  required
                  rows="4"
                />
                <FileUpload
                  label="LinkedIn Profile or Cover Letter (PDF)"
                  id="linkedinProfile"
                  accept=".pdf"
                  onChange={(e) => setLinkedinProfile(e.target.files[0])}
                />
              </div>
              <div className="md:col-span-2 mt-4">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full text-lg py-3"
                >
                  {isSubmitting ? 'AI Magic in Progress...' : 'Generate AI-Powered Resume & Cover Letter'}
                </Button>
              </div>
            </form>
          </div>
        </motion.section>

        <section className="mb-12">
          <h2 className={`text-3xl md:text-4xl font-bold mb-8 text-center ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
            AI-Driven Efficiency
          </h2>
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6">
            <div className="flex mb-4">
              <button
                className={`mr-4 px-4 py-2 rounded ${activeTab === 'timeComparison' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                onClick={() => setActiveTab('timeComparison')}
              >
                Time Comparison
              </button>
              <button
                className={`px-4 py-2 rounded ${activeTab === 'timeSaved' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                onClick={() => setActiveTab('timeSaved')}
              >
                Time Saved
              </button>
            </div>
            <div className="w-full max-w-2xl mx-auto" style={{ height: '300px' }}>
              {activeTab === 'timeComparison' ? (
                <Bar 
                  data={timeComparisonData} 
                  options={{ 
                    responsive: true, 
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: 'Time (minutes)'
                        }
                      }
                    }
                  }} 
                />
              ) : (
                <Pie 
                  data={timeSavedData} 
                  options={{ 
                    responsive: true, 
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }} 
                />
              )}
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl md:text-3xl font-bold mb-6">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <FAQ 
              question="How soon will I receive the final documents?" 
              answer="Typically within 5 minutes of submission."
            />
            <FAQ 
              question="Is my data secure?" 
              answer="Yes. We use encryption and secure connections to protect your information."
            />
            <FAQ 
              question="Can I make further edits to the documents?" 
              answer="Absolutely. Your PDFs are ready for submission, but you can make any additional edits if needed."
            />
          </div>
        </section>
      </div>

      <AnimatePresence>
        {showSuccessCard && (
          <SuccessCard
            onClose={() => setShowSuccessCard(false)}
            isDarkMode={isDarkMode}
            isConstructFuture={isConstructFuture}
          />
        )}
      </AnimatePresence>
      {/* Add the DAS140Agent component */}
      <DAS140Agent />
    </div>
  );
}

function InputField({ label, id, type = "text", value, onChange, required }) {
  return (
    <div>
      <label htmlFor={id} className="block mb-1 font-medium">{label}</label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </div>
  );
}

function FileUpload({ label, id, accept, onChange, required }) {
  return (
    <div>
      <label htmlFor={id} className="block mb-1 font-medium">{label}</label>
      <input
        type="file"
        id={id}
        accept={accept}
        onChange={onChange}
        required={required}
        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </div>
  );
}

function TextArea({ label, id, value, onChange, required, rows }) {
  return (
    <div>
      <label htmlFor={id} className="block mb-1 font-medium">{label}</label>
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        rows={rows}
        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      ></textarea>
    </div>
  );
}

function getStepDescription(step) {
  switch (step) {
    case 'Upload':
      return "Upload your resume, job description, and optional LinkedIn profile.";
    case 'Analyze':
      return "Our AI analyzes your documents and the job requirements.";
    case 'Generate':
      return "AI creates tailored resume and cover letter based on the analysis.";
    case 'Deliver':
      return "Receive your optimized documents ready for submission.";
    default:
      return "";
  }
}

function AgentCard({ title, icon, description, features, timeSaved }) {
  const { isDarkMode } = useContext(ThemeContext);
  
  return (
    <motion.div 
      className={`rounded-lg p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-2xl font-bold mb-3">{title}</h3>
      <p className="mb-4">{description}</p>
      <ul className="list-disc list-inside mb-4">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <div className="text-lg font-semibold">
        Time Saved: <span className="text-green-500">{timeSaved}</span>
      </div>
    </motion.div>
  );
}

function TableRow({ task, manual, ai, saved, isTotal = false }) {
  const rowClass = isTotal ? "font-bold" : "";
  return (
    <tr className={`border-b ${rowClass}`}>
      <td className="py-2">{task}</td>
      <td className="py-2">{manual}</td>
      <td className="py-2">{ai}</td>
      <td className="py-2 text-green-500">{saved}</td>
    </tr>
  );
}

function FAQ({ question, answer }) {
  return (
    <div className="mb-4">
      <h3 className="font-bold mb-2">{question}</h3>
      <p>{answer}</p>
    </div>
  );
}

function SuccessCard({ onClose, isDarkMode, isConstructFuture }) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
    >
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className={`relative ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-xl p-6 max-w-md w-full`}>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
        <h2 className={`text-2xl font-bold mb-4 ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
          Success!
        </h2>
        <p className="mb-6">
          You will receive your customized resume and cover letter in a few minutes. In the meantime, why not explore our other AI agents?
        </p>
        <div className="space-y-4">
          <AgentSuggestionCard
            title="AI Interview Coach"
            description="Practice interviews with AI-powered feedback."
            icon="🎙️"
          />
          <AgentSuggestionCard
            title="Career Path Analyzer"
            description="Get AI insights on your optimal career trajectory."
            icon="📊"
          />
          <AgentSuggestionCard
            title="Skill Gap Identifier"
            description="Discover and bridge your skill gaps with AI assistance."
            icon="🧠"
          />
        </div>
      </div>
    </motion.div>
  );
}

function AgentSuggestionCard({ title, description, icon }) {
  return (
    <div className="flex items-center space-x-4 p-3 bg-gray-100 dark:bg-gray-700 rounded-lg">
      <div className="text-3xl">{icon}</div>
      <div>
        <h3 className="font-semibold">{title}</h3>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
}

export default AIResumeAgent;
