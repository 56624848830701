import React from 'react';
import Helmet from 'react-helmet';
import { motion } from 'framer-motion';
import { 
  Eye,
  Shield,
  LineChart,
  ClipboardCheck,
  Brain,
  CheckCircle,
  ArrowRight,
  Clock,
  Target,
  Settings,
  Camera,
  AlertCircle,
  BarChart2,
  Lock,
  Users,
  Cpu,
  Wrench
} from 'lucide-react';

const QualityControl = () => {
  const features = [
    {
      icon: <Camera className="w-12 h-12 text-blue-500" />,
      title: 'Automated Visual Inspections',
      description: 'Utilize AI-driven computer vision technology to detect defects, deviations from plans, and safety hazards in real-time.',
      benefits: [
        'Early issue detection',
        'Increased inspection efficiency',
        'Reduced labor costs'
      ],
      techDetails: [
        'Advanced image recognition',
        'Real-time analysis',
        '3D modeling integration'
      ]
    },
    {
      icon: <Shield className="w-12 h-12 text-green-500" />,
      title: 'Real-Time Compliance Monitoring',
      description: 'Ensure adherence to industry standards and building codes with continuous monitoring and automated verification.',
      benefits: [
        'Risk mitigation',
        'Consistent quality standards',
        'Simplified audits'
      ],
      techDetails: [
        'Regulation database integration',
        'Automated checklist verification',
        'Exception reporting system'
      ]
    },
    {
      icon: <LineChart className="w-12 h-12 text-purple-500" />,
      title: 'Predictive Quality Analytics',
      description: 'Leverage AI to predict and prevent potential quality issues before they occur through pattern analysis.',
      benefits: [
        'Proactive quality management',
        'Resource optimization',
        'Continuous improvement'
      ],
      techDetails: [
        'Machine learning models',
        'Anomaly detection',
        'Multi-source data integration'
      ]
    },
    {
      icon: <ClipboardCheck className="w-12 h-12 text-indigo-500" />,
      title: 'Standardized Quality Processes',
      description: 'Implement and enforce consistent quality control processes across all projects and teams.',
      benefits: [
        'Process consistency',
        'Improved efficiency',
        'Enhanced accountability'
      ],
      techDetails: [
        'Workflow automation',
        'Knowledge base management',
        'Training support system'
      ]
    }
  ];

  const stats = [
    {
      icon: <Target className="w-8 h-8 text-blue-500" />,
      value: "30",
      unit: "%",
      label: "Reduction in Rework Costs"
    },
    {
      icon: <Clock className="w-8 h-8 text-green-500" />,
      value: "20",
      unit: "%",
      label: "Shorter Project Timelines"
    },
    {
      icon: <BarChart2 className="w-8 h-8 text-purple-500" />,
      value: "220",
      unit: "%",
      label: "ROI in First Year"
    }
  ];

  const aiCapabilities = [
    {
      icon: <Eye className="w-12 h-12 text-rose-500" />,
      title: 'Computer Vision',
      description: 'Advanced visual analysis for defect detection and quality assurance.',
      capabilities: [
        'Image and video analysis',
        'Defect recognition',
        'Real-time monitoring'
      ]
    },
    {
      icon: <Brain className="w-12 h-12 text-amber-500" />,
      title: 'Deep Learning',
      description: 'Sophisticated pattern recognition for quality prediction.',
      capabilities: [
        'Pattern recognition',
        'Predictive analytics',
        'Continuous learning'
      ]
    },
    {
      icon: <Cpu className="w-12 h-12 text-cyan-500" />,
      title: 'Process Automation',
      description: 'Streamlined quality control workflows and reporting.',
      capabilities: [
        'Automated reporting',
        'Workflow optimization',
        'Data integration'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>AI-Powered Quality Control | Construct Regulate</title>
        <meta name="description" content="Implement AI-powered quality control measures that detect issues early, ensure compliance with industry standards, and maintain consistent quality across construction projects." />
        <meta name="keywords" content="construction quality control, AI automation, visual inspections, compliance monitoring, quality analytics, construction standards" />
        <meta property="og:title" content="AI-Powered Quality Control | Construct Regulate" />
        <meta property="og:description" content="Transform your construction quality control with AI automation. Detect issues early and maintain consistent quality across projects." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AI-Powered Quality Control | Construct Regulate" />
        <meta name="twitter:description" content="Transform your construction quality control with AI automation. Detect issues early and maintain consistent quality across projects." />
      </Helmet>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Quality Control Automation
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Implement AI-powered quality control measures that detect issues early, ensure compliance with industry standards, and maintain consistent quality across your construction projects.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                Schedule Demo
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Learn More
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Revolutionize Quality Control with AI
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              Traditional quality control methods often rely on manual inspections and reactive problem-solving. Our AI-powered solutions transform this approach with automated inspections and proactive issue detection.
            </p>
            
            {/* Stats Section */}
            <div className="grid md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex justify-center mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-blue-900 mb-2">
                    {stat.value}<span className="text-blue-500">{stat.unit}</span>
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Key Features</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our comprehensive quality control system combines advanced AI technologies to ensure excellence in every aspect of your construction projects.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="mb-6">{feature.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.description}</p>
                
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Benefits</h4>
                    <ul className="space-y-3">
                      {feature.benefits.map((benefit, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Technical Details</h4>
                    <ul className="space-y-3">
                      {feature.techDetails.map((detail, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{detail}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* AI Capabilities Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Advanced AI Capabilities</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Powered by cutting-edge artificial intelligence technologies that enhance every aspect of quality control.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {aiCapabilities.map((capability, index) => (
              <motion.div
                key={capability.title}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5 }}
              >
                <div className="mb-6">{capability.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{capability.title}</h3>
                <p className="text-gray-600 mb-6">{capability.description}</p>
                <ul className="space-y-3">
                  {capability.capabilities.map((item, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Case Study Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-white rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">Success Story: High-Rise Building Project</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-xl mb-2">Challenge:</h3>
                <p className="text-gray-600">
                  A construction company faced frequent quality issues detected late in the project lifecycle, leading to costly rework and delays.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Solution:</h3>
                <p className="text-gray-600">
                  We implemented AI-driven visual inspection tools and predictive analytics to detect and prevent quality issues early in the process.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Results:</h3>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">30% reduction in rework costs</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">20% shorter project timelines</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">220% ROI in the first year</span>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Implementation Process */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Implementation Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our structured approach ensures a smooth transition to AI-powered quality control.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-6 gap-8">
            {[
              {
                step: "1",
                title: "Quality Assessment",
                description: "Evaluate current processes and challenges",
                icon: <ClipboardCheck className="w-8 h-8 text-blue-500" />
              },
              {
                step: "2",
                title: "Solution Design",
                description: "Customize AI models for your needs",
                icon: <Settings className="w-8 h-8 text-green-500" />
              },
              {
                step: "3",
                title: "Data Collection",
                description: "Gather and prepare training data",
                icon: <Eye className="w-8 h-8 text-purple-500" />
              },
              {
                step: "4",
                title: "Testing",
                description: "Validate performance with pilot program",
                icon: <Wrench className="w-8 h-8 text-indigo-500" />
              },
              {
                step: "5",
                title: "Deployment",
                description: "Install and configure systems",
                icon: <Wrench className="w-8 h-8 text-indigo-500" />
              },
              {
                step: "6",
                title: "Training",
                description: "Educate teams on new tools",
                icon: <Users className="w-8 h-8 text-rose-500" />
              },
              {
                step: "7",
                title: "Optimization",
                description: "Refine and improve performance",
                icon: <LineChart className="w-8 h-8 text-amber-500" />
              }
            ].map((phase, index) => (
              <motion.div
                key={phase.step}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mx-auto mb-6 shadow-md">
                  <span className="text-blue-600 font-bold text-xl">{phase.step}</span>
                </div>
                <div className="flex justify-center mb-4">{phase.icon}</div>
                <h3 className="text-xl font-bold mb-3">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Security Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold mb-4">Security & Compliance</h2>
              <p className="text-gray-600">
                We prioritize the security and privacy of your quality control data with enterprise-grade protection.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Data Protection",
                  description: "End-to-end encryption for all quality data",
                  icon: <Lock className="w-8 h-8 text-blue-500" />
                },
                {
                  title: "Compliance",
                  description: "Adherence to industry standards",
                  icon: <Shield className="w-8 h-8 text-green-500" />
                },
                {
                  title: "Access Control",
                  description: "Role-based permissions system",
                  icon: <AlertCircle className="w-8 h-8 text-purple-500" />
                }
              ].map((item, index) => (
                <motion.div
                  key={item.title}
                  className="bg-white rounded-xl shadow-lg p-6 text-center"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex justify-center mb-4">{item.icon}</div>
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "How accurate are the AI-powered visual inspections?",
                  answer: "Our AI models are trained to identify defects with high accuracy, often surpassing human capabilities in consistently detecting subtle issues."
                },
                {
                  question: "What equipment is required for implementation?",
                  answer: "The system can use existing cameras, drones, or mobile devices. We'll help determine the best equipment setup for your needs."
                },
                {
                  question: "How does the system stay updated with changing standards?",
                  answer: "We regularly update our regulation databases and AI models to reflect the latest industry standards and requirements."
                },
                {
                  question: "Is training provided for our team?",
                  answer: "Yes, we provide comprehensive training and ongoing support to ensure your team is confident in using the quality control systems."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-xl p-6"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <h3 className="text-xl font-bold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Take Quality Control to the Next Level</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Transform your quality management with AI automation. Detect issues early, maintain consistent standards, and deliver superior projects.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule Your Free Consultation
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default QualityControl;
