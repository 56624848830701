import React from 'react';
import { Link } from 'react-router-dom';

const AgentCard = ({ agent }) => {
  return (
    <div className="inline-block w-80 bg-gradient-to-br from-gray-100 to-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
      <div className="relative">
        <img src={agent.image} alt={agent.name} className="w-full h-48 object-cover" />
        <div className="absolute top-0 right-0 bg-blue-600 text-white px-2 py-1 text-sm rounded-bl-lg">
          AI Agent
        </div>
      </div>
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-2 text-gray-800">{agent.name}</h2>
        <p className="text-gray-600 mb-4 line-clamp-2">{agent.description}</p>
        <div className="mb-4">
          <h3 className="text-sm font-semibold mb-2 text-gray-700">Time-Saving Features:</h3>
          <ul className="list-disc list-inside text-sm text-gray-600">
            {agent.features && agent.features.slice(0, 3).map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
        {agent.link && (
          <Link
            to={agent.link}
            className="inline-block bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300"
          >
            Learn More
          </Link>
        )}
      </div>
    </div>
  );
};

export default AgentCard;
