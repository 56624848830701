import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet'; // Add this import
import Button from '../components/Button';
import { ThemeContext } from '../contexts/ThemeContext';
import ParticleBackground from '../components/ParticleBackground';
import axios from 'axios';
import { API_BASE_URL } from '../config';

function HomePage() {
  const { currentStyle, isDarkMode } = useContext(ThemeContext);
  const isConstructFuture = currentStyle === 'Construct Future';
  const [featuredAgents, setFeaturedAgents] = useState([]);

  useEffect(() => {
    const fetchFeaturedAgents = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/agents`);
        setFeaturedAgents(response.data);
      } catch (error) {
        console.error('Error fetching featured agents:', error);
      }
    };

    fetchFeaturedAgents();
  }, []);

  return (
    <div className={`home-page relative ${isConstructFuture ? 'bg-construct-light dark:bg-construct-dark text-construct-dark dark:text-white' : 'bg-gradient-to-br from-blue-200 via-purple-200 to-pink-200 dark:from-blue-900 dark:via-purple-900 dark:to-pink-900'}`}>
      <Helmet>
        <title>Construct Regulate - AI-Powered Construction Management</title>
        <meta name="description" content="Revolutionize your construction processes with AI-powered automation. Streamline project management, financial control, and compliance." />
        <meta name="keywords" content="construction, AI, automation, project management, financial control, compliance" />
        <meta property="og:title" content="Construct Regulate - AI-Powered Construction Management" />
        <meta property="og:description" content="Revolutionize your construction processes with AI-powered automation. Streamline project management, financial control, and compliance." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com" />
        <meta property="og:image" content="https://constructregulate.com/images/og-image.jpg" />
        <link rel="canonical" href="https://constructregulate.com" />
      </Helmet>
      <ParticleBackground isDarkMode={isDarkMode} />
      <div className="relative z-10">
        <HeroSection />
        <ServicesOverview />
        <Benefits />
        <Testimonials />
        <AIAgentsSection />
        <FeaturedAgentsSection featuredAgents={featuredAgents} />
      </div>
    </div>
  );
}

function HeroSection() {
  const { currentStyle } = useContext(ThemeContext);
  const isConstructFuture = currentStyle === 'Construct Future';

  return (
    <section className={`hero ${isConstructFuture ? 'bg-construct-primary' : 'bg-gradient-to-r from-blue-600 to-purple-600'} py-32 relative overflow-hidden`}>
      <div className="container mx-auto text-center relative z-10">
        <motion.h1 
          className={`text-6xl font-extrabold mb-6 ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'} text-gray-900`}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Revolutionize Construction<br />with AI-Powered Automation
        </motion.h1>
        <motion.p 
          className="text-2xl mb-10 max-w-2xl mx-auto text-gray-800"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Streamline processes, boost efficiency, and drive growth with our cutting-edge AI solutions
        </motion.p>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <Link to="/contact">
            <Button className="px-8 py-4 text-lg bg-blue-600 text-white hover:bg-blue-700 transition duration-300">Get Started Free</Button>
          </Link>
        </motion.div>
      </div>
    </section>
  );
}

function ServicesOverview() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className="services-overview py-24">
      <div className="container mx-auto">
        <motion.h2 
          className="text-4xl font-bold mb-16 text-center font-heading dark:text-white"
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 0.5 }}
        >
          Transforming Construction with AI
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <AnimatedServiceCard 
            title="Smart Project Management"
            description="AI-driven scheduling, resource allocation, and risk prediction for seamless project execution."
            icon="📊"
            delay={0}
          />
          <AnimatedServiceCard 
            title="Intelligent Financial Control"
            description="Automated invoicing, precise budgeting, and AI-powered financial forecasting."
            icon="💰"
            delay={0.2}
          />
          <AnimatedServiceCard 
            title="Optimized Resource Allocation"
            description="AI-optimized workforce and equipment distribution for maximum efficiency."
            icon="🔧"
            delay={0.4}
          />
        </div>
      </div>
    </section>
  );
}

function AnimatedServiceCard({ title, description, icon, delay }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className="card hover-card"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay }}
      whileHover={{ scale: 1.05 }}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </motion.div>
  );
}

function Benefits() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className="benefits py-24">
      <div className="container mx-auto">
        <motion.h2 
          className="text-4xl font-bold mb-16 text-center font-heading dark:text-white"
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 0.5 }}
        >
          Why Choose Our AI Solution?
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <AnimatedBenefitCard 
            title="70% Time Savings"
            description="Dramatically reduce manual tasks, allowing your team to focus on strategic initiatives."
            icon="⏱️"
            delay={0}
          />
          <AnimatedBenefitCard 
            title="99% Accuracy Increase"
            description="Minimize human errors in data entry and calculations for unparalleled precision."
            icon="🎯"
            delay={0.2}
          />
          <AnimatedBenefitCard 
            title="30% Cost Reduction"
            description="Lower operational costs through AI-driven resource optimization and efficiency gains."
            icon="💸"
            delay={0.4}
          />
          <AnimatedBenefitCard 
            title="Unlimited Scalability"
            description="Easily adapt to project size fluctuations without the need for additional hiring."
            icon="📈"
            delay={0.6}
          />
        </div>
      </div>
    </section>
  );
}

function AnimatedBenefitCard({ title, description, icon, delay }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className="card hover-card"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </motion.div>
  );
}

function Testimonials() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className="testimonials py-24">
      <div className="container mx-auto">
        <motion.h2 
          className="text-4xl font-bold mb-16 text-center font-heading dark:text-white"
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 0.5 }}
        >
          What Our Clients Say
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <AnimatedTestimonialCard 
            quote="Construct Regulate's AI agents have revolutionized our project management. We've seen a 40% increase in on-time project completions and a 25% reduction in costs."
            author="John Doe, CEO of BuildRight Inc."
            delay={0}
          />
          <AnimatedTestimonialCard 
            quote="The financial management AI has streamlined our invoicing process, reducing errors by 90% and improving cash flow. It's been a game-changer for our operations."
            author="Jane Smith, CFO of Construct Solutions"
            delay={0.2}
          />
        </div>
      </div>
    </section>
  );
}

function AnimatedTestimonialCard({ quote, author, delay }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className="card hover-card"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={inView ? { opacity: 1, scale: 1 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      <p className="italic mb-6 text-lg dark:text-gray-300">"{quote}"</p>
      <p className="font-bold dark:text-white">- {author}</p>
    </motion.div>
  );
}

function AIAgentsSection() {
  const { isDarkMode } = useContext(ThemeContext);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [autoRotate, setAutoRotate] = useState(true);
  const containerRef = useRef(null);

  const agents = [
    {
      id: 1,
      name: "ProjectMaster AI",
      icon: "📊",
      description: "Optimizes schedules, allocates resources, and predicts project timelines with uncanny accuracy.",
      capabilities: ["Smart Scheduling", "Resource Optimization", "Risk Prediction"],
      color: "from-blue-400 to-blue-600"
    },
    {
      id: 2,
      name: "SafetyGuard AI",
      icon: "🛡️",
      description: "Monitors job sites in real-time, identifying potential hazards and ensuring OSHA compliance.",
      capabilities: ["Real-time Monitoring", "Hazard Identification", "OSHA Compliance"],
      color: "from-green-400 to-green-600"
    },
    {
      id: 3,
      name: "FinanceWiz AI",
      icon: "💰",
      description: "Manages budgets, forecasts expenses, and optimizes financial operations for construction projects.",
      capabilities: ["Budget Management", "Expense Forecasting", "Financial Optimization"],
      color: "from-yellow-400 to-yellow-600"
    },
    {
      id: 4,
      name: "QualityInspector AI",
      icon: "🔍",
      description: "Ensures top-notch quality control through AI-powered inspections and defect detection.",
      capabilities: ["AI-powered Inspections", "Defect Detection", "Quality Control"],
      color: "from-red-400 to-red-600"
    },
    {
      id: 5,
      name: "SupplyChain AI",
      icon: "🔗",
      description: "Optimizes procurement processes and manages inventory with predictive analytics.",
      capabilities: ["Procurement Optimization", "Inventory Management", "Predictive Analytics"],
      color: "from-purple-400 to-purple-600"
    }
  ];

  useEffect(() => {
    if (autoRotate && containerRef.current) {
      const interval = setInterval(() => {
        containerRef.current.scrollLeft += 1;
        if (containerRef.current.scrollLeft >= containerRef.current.scrollWidth - containerRef.current.clientWidth) {
          containerRef.current.scrollLeft = 0;
        }
      }, 50);
      return () => clearInterval(interval);
    }
  }, [autoRotate]);

  return (
    <section className="ai-agents-section py-20 relative overflow-hidden">
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 
          className={`text-5xl font-bold text-center mb-16 ${isDarkMode ? 'text-white' : 'text-gray-900'} font-heading`}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Meet Our AI Agents
        </motion.h2>
        <div 
          ref={containerRef}
          className="flex overflow-x-scroll hide-scroll-bar"
          onMouseEnter={() => setAutoRotate(false)}
          onMouseLeave={() => setAutoRotate(true)}
        >
          <div className="flex space-x-8 pb-8">
            {agents.map((agent, index) => (
              <AgentCard 
                key={agent.id}
                agent={agent}
                isDarkMode={isDarkMode}
                onClick={() => setSelectedAgent(agent)}
              />
            ))}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {selectedAgent && (
          <FullScreenAgentCard
            agent={selectedAgent}
            onClose={() => setSelectedAgent(null)}
            isDarkMode={isDarkMode}
          />
        )}
      </AnimatePresence>
    </section>
  );
}

function AgentCard({ agent, isDarkMode, onClick }) {
  return (
    <motion.div 
      className={`agent-card ${agent.color} rounded-3xl overflow-hidden cursor-pointer shadow-2xl flex-shrink-0`}
      style={{ width: "300px", height: "400px" }}
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
    >
      <div className="h-full w-full p-6 flex flex-col justify-between">
        <div>
          <div className="text-6xl mb-4">{agent.icon}</div>
          <h3 className="text-2xl font-bold mb-2 text-white">{agent.name}</h3>
          <p className="text-sm mb-4 text-white">{agent.description}</p>
        </div>
      </div>
    </motion.div>
  );
}

function FullScreenAgentCard({ agent, onClose, isDarkMode }) {
  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className={`${agent.color} rounded-3xl overflow-hidden shadow-2xl w-4/5 h-4/5 relative`}
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
      >
        <button
          className="absolute top-4 right-4 text-white text-2xl"
          onClick={onClose}
        >
          ×
        </button>
        <div className="h-full w-full p-12 flex flex-col justify-between overflow-y-auto">
          <div>
            <div className="text-9xl mb-8">{agent.icon}</div>
            <h3 className="text-5xl font-bold mb-6 text-white">{agent.name}</h3>
            <p className="text-2xl mb-8 text-white">{agent.description}</p>
            <h4 className="text-3xl font-semibold mb-4 text-white">Capabilities:</h4>
            <ul className="list-disc list-inside text-xl text-white mb-8">
              {agent.capabilities.map((capability, index) => (
                <li key={index}>{capability}</li>
              ))}
            </ul>
          </div>
          <div className="flex space-x-4">
            <Button className="px-6 py-3 bg-white text-blue-600 hover:bg-blue-100">
              Learn More
            </Button>
            <Link to="/agents">
              <Button className="px-6 py-3 bg-blue-600 text-white hover:bg-blue-700">
                View All Agents
              </Button>
            </Link>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

function FeaturedAgentsSection({ featuredAgents }) {
  return (
    <section className="featured-agents py-16">
      <h2 className="text-3xl font-bold mb-8 text-center">Featured AI Agents</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {featuredAgents.map(agent => (
          <div key={agent.id} className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-xl font-bold mb-2">{agent.name}</h3>
            <p className="mb-4">{agent.description}</p>
            <p><strong>Category:</strong> {agent.category}</p>
            <p><strong>Automation:</strong> {agent.automation_percentage}%</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HomePage;
