import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { 
  Receipt,
  BarChart2,
  TrendingUp,
  FileText,
  Brain,
  Bot,
  CheckCircle,
  ArrowRight,
  Clock,
  Wallet,
  PieChart,
  Shield,
  AlertCircle,
  Settings,
  LayoutGrid,
  Link,
  Lock,
  ClipboardList,
  Users
} from 'lucide-react';

const FinancialManagement = () => {
  const features = [
    {
      icon: <Receipt className="w-12 h-12 text-blue-500" />,
      title: 'Automated Invoicing & Billing',
      description: 'Streamline your entire invoicing process with AI-powered automation that ensures accuracy and speeds up payment collection.',
      benefits: [
        'Reduce manual entry errors',
        'Accelerate payment cycles',
        'Enhance client relationships'
      ],
      techDetails: [
        'ERP & accounting software integration',
        'Automated invoice generation',
        'Smart payment tracking system'
      ]
    },
    {
      icon: <BarChart2 className="w-12 h-12 text-green-500" />,
      title: 'Real-Time Budgeting',
      description: 'Gain complete visibility into project expenses with real-time budget tracking and intelligent expense monitoring.',
      benefits: [
        'Enhanced financial oversight',
        'Early overspending detection',
        'Stakeholder transparency'
      ],
      techDetails: [
        'Dynamic budgeting models',
        'Automated expense categorization',
        'Real-time threshold alerts'
      ]
    },
    {
      icon: <TrendingUp className="w-12 h-12 text-purple-500" />,
      title: 'Advanced Financial Forecasting',
      description: 'Leverage AI-driven predictive analytics to forecast cash flow, revenue, and financial needs with unprecedented accuracy.',
      benefits: [
        'Data-driven strategic planning',
        'Improved risk management',
        'Enhanced opportunity identification'
      ],
      techDetails: [
        'Machine learning algorithms',
        'Scenario analysis engine',
        'Risk assessment models'
      ]
    },
    {
      icon: <FileText className="w-12 h-12 text-indigo-500" />,
      title: 'Intelligent Expense Management',
      description: 'Automate expense processing and vendor payments while ensuring policy compliance and optimal spending.',
      benefits: [
        'Streamlined approval workflows',
        'Enhanced policy compliance',
        'Reduced processing costs'
      ],
      techDetails: [
        'OCR receipt scanning',
        'Automated policy enforcement',
        'Smart vendor management'
      ]
    }
  ];

  const aiCapabilities = [
    {
      icon: <Brain className="w-12 h-12 text-rose-500" />,
      title: 'Natural Language Processing',
      description: 'Process financial documents and communications automatically.',
      capabilities: [
        'Automated data extraction',
        'Financial document analysis',
        'Intelligent query responses'
      ]
    },
    {
      icon: <TrendingUp className="w-12 h-12 text-amber-500" />,
      title: 'Predictive Analytics',
      description: 'Forecast financial trends and identify opportunities.',
      capabilities: [
        'Cash flow prediction',
        'Risk assessment',
        'Market trend analysis'
      ]
    },
    {
      icon: <Bot className="w-12 h-12 text-cyan-500" />,
      title: 'Robotic Process Automation',
      description: 'Automate routine financial tasks and processes.',
      capabilities: [
        'Data entry automation',
        'Report generation',
        'Transaction reconciliation'
      ]
    }
  ];

  const stats = [
    {
      icon: <Clock className="w-8 h-8 text-blue-500" />,
      value: "30",
      unit: "%",
      label: "Reduction in DSO"
    },
    {
      icon: <Wallet className="w-8 h-8 text-green-500" />,
      value: "25",
      unit: "%",
      label: "Decrease in Operational Costs"
    },
    {
      icon: <PieChart className="w-8 h-8 text-purple-500" />,
      value: "200",
      unit: "%",
      label: "ROI in First Year"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Financial Management Automation | Construct Regulate</title>
        <meta name="description" content="Transform your construction company's financial operations with AI-powered automation. Streamline invoicing, optimize budgeting, and gain actionable insights for improved financial performance." />
        <meta name="keywords" content="financial management, construction, AI automation, invoicing, budgeting, financial forecasting" />
        <meta property="og:title" content="Financial Management Automation | Construct Regulate" />
        <meta property="og:description" content="Transform your construction company's financial operations with AI-powered automation. Streamline invoicing, optimize budgeting, and gain actionable insights for improved financial performance." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/services/financial-management" />
        <meta property="og:image" content="https://constructregulate.com/images/financial-management-og.jpg" />
        <link rel="canonical" href="https://constructregulate.com/services/financial-management" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Financial Management Automation
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Transform your construction company's financial operations with AI-powered automation. Streamline invoicing, optimize budgeting, and gain actionable insights for improved financial performance.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                Schedule Demo
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Learn More
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Revolutionize Financial Management with AI
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              Traditional financial management struggles with manual processes, inefficient budgeting, and inaccurate forecasting. Our AI-driven solutions transform these challenges into opportunities for growth.
            </p>
            
            {/* Stats Section */}
            <div className="grid md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex justify-center mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-blue-900 mb-2">
                    {stat.value}<span className="text-blue-500">{stat.unit}</span>
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Key Financial Features</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our comprehensive suite of AI-powered features transforms every aspect of financial management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="mb-6">{feature.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.description}</p>
                
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Benefits</h4>
                    <ul className="space-y-3">
                      {feature.benefits.map((benefit, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Technical Details</h4>
                    <ul className="space-y-3">
                      {feature.techDetails.map((detail, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{detail}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* AI Capabilities Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Advanced AI Capabilities</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Powered by cutting-edge artificial intelligence technologies that enhance every aspect of financial management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {aiCapabilities.map((capability, index) => (
              <motion.div
                key={capability.title}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5 }}
              >
                <div className="mb-6">{capability.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{capability.title}</h3>
                <p className="text-gray-600 mb-6">{capability.description}</p>
                <ul className="space-y-3">
                  {capability.capabilities.map((item, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Security Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold mb-4">Security & Compliance</h2>
              <p className="text-gray-600">
                Your financial data security is our top priority. We implement enterprise-grade security measures and comply with industry standards.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Advanced Encryption",
                  description: "End-to-end encryption for all financial data",
                  icon: <Lock className="w-8 h-8 text-blue-500" />
                },
                {
                  title: "Regulatory Compliance",
                  description: "SOX, GDPR, and PCI DSS compliant",
                  icon: <Shield className="w-8 h-8 text-green-500" />
                },
                {
                  title: "Risk Management",
                  description: "Continuous monitoring and threat prevention",
                  icon: <AlertCircle className="w-8 h-8 text-purple-500" />
                }
              ].map((item, index) => (
                <motion.div
                  key={item.title}
                  className="bg-white rounded-xl shadow-lg p-6 text-center"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex justify-center mb-4">{item.icon}</div>
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Case Study Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">Success Story: Mid-Sized Construction Firm</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-xl mb-2">Challenge:</h3>
                <p className="text-gray-600">
                  The firm struggled with delayed invoicing and poor cash flow management, leading to financial strain and difficulty funding new projects.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Solution:</h3>
                <p className="text-gray-600">
                  We implemented AI-driven automation for invoicing and expense tracking, along with real-time budgeting tools and predictive analytics for cash flow forecasting.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Results:</h3>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">30% reduction in Days Sales Outstanding (DSO)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">25% decrease in operational costs through automation</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">200% ROI achieved within the first year</span>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Implementation Process */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Implementation Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our structured approach ensures a smooth transition to AI-powered financial management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-5 gap-8">
            {[
              {
                step: "1",
                title: "Financial Assessment",
                description: "Review existing processes and identify automation opportunities",
                icon: <ClipboardList className="w-8 h-8 text-blue-500" />
              },
              {
                step: "2",
                title: "Solution Design",
                description: "Customize AI solutions to match your workflows",
                icon: <LayoutGrid className="w-8 h-8 text-green-500" />
              },
              {
                step: "3",
                title: "Integration",
                description: "Connect with existing financial systems",
                icon: <Link className="w-8 h-8 text-purple-500" />
              },
              {
                step: "4",
                title: "Team Training",
                description: "Comprehensive training for your finance team",
                icon: <Users className="w-8 h-8 text-indigo-500" />
              },
              {
                step: "5",
                title: "Optimization",
                description: "Continuous monitoring and refinement",
                icon: <Settings className="w-8 h-8 text-rose-500" />
              }
            ].map((phase, index) => (
              <motion.div
                key={phase.step}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mx-auto mb-6 shadow-md">
                  <span className="text-blue-600 font-bold text-xl">{phase.step}</span>
                </div>
                <div className="flex justify-center mb-4">{phase.icon}</div>
                <h3 className="text-xl font-bold mb-3">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "How does AI improve financial data accuracy?",
                  answer: "AI reduces manual data entry errors and uses machine learning to detect anomalies and inconsistencies in financial data, ensuring higher accuracy."
                },
                {
                  question: "Will AI integration disrupt our current financial operations?",
                  answer: "We implement changes gradually with minimal disruption, starting with non-critical processes before scaling up to ensure smooth transition."
                },
                {
                  question: "Is our financial data secure with AI systems?",
                  answer: "Yes, we employ enterprise-grade encryption, strict access controls, and comply with all relevant financial regulations to ensure data security."
                },
                {
                  question: "How soon can we expect to see ROI?",
                  answer: "Most clients see significant improvements in cash flow and cost savings within the first few months, with full ROI typically achieved within the first year."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-xl p-6"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <h3 className="text-xl font-bold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Take Control of Your Financial Future</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Transform your financial operations with AI automation. Improve cash flow, enhance accuracy, and make better strategic decisions.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule Your Free Consultation
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FinancialManagement;
