import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Button from '../components/Button';

function DAS140Agent() {
  const [occupations, setOccupations] = useState(['']);
  const [county, setCounty] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationStep, setGenerationStep] = useState(0);
  const [factIndex, setFactIndex] = useState(0);

  const generationSteps = [
    "Initializing DAS-140 form generation process...",
    "Validating project and trade information...",
    "Connecting to Department of Industrial Relations database...",
    "Fetching apprenticeship program data for selected trades...",
    "Processing apprenticeship information...",
    "Generating individual DAS-140 forms...",
    "Compiling forms for each trade and program...",
    "Creating downloadable ZIP archive...",
    "Finalizing DAS-140 form package..."
  ];

  const didYouKnowFacts = [
    "By automating DAS-140 form generation, you're saving up to 1 hour and 57 minutes compared to manual processing for 20 forms.",
    "Automated DAS-140 form submission reduces the risk of errors by up to 95%, helping you avoid costly fines and delays.",
    "Construction companies using AI-driven compliance tools report a 30% increase in overall productivity.",
    "Automated DAS-140 processing can help you manage up to 5 times more projects without increasing your administrative staff.",
    "Companies using AI for compliance report a 40% reduction in audit-related stress and workload.",
    "Automated DAS-140 form generation ensures 100% compliance with the latest California Department of Industrial Relations standards."
  ];

  useEffect(() => {
    if (isGenerating) {
      const stepInterval = setInterval(() => {
        setGenerationStep((prevStep) => (prevStep + 1) % generationSteps.length);
      }, 3000);

      const factInterval = setInterval(() => {
        setFactIndex((prevIndex) => (prevIndex + 1) % didYouKnowFacts.length);
      }, 15000);

      return () => {
        clearInterval(stepInterval);
        clearInterval(factInterval);
      };
    }
  }, [isGenerating, generationSteps.length, didYouKnowFacts.length]);

  const TRADE_NAMES = [
    "Agriculture", "Asbestos Workers", "Automotive", "Barber / Cosmetology",
    "Boilermaker", "Bricklayer", "Carpentry", "Carpet Linoleum",
    "Cement Masons", "Civil Service", "Drywall / Lathers", "Drywall Finisher (Taper)",
    "Electrical & Electronic", "Elevator Mechanic", "Entertainment",
    "Glazier & Glass Workers", "Health Services", "Heating, Ventilation & Air Conditioning",
    "Hospitality", "Information Technology", "Inspector / Tester",
    "Iron & Steel Workers", "Laborers", "Lineman", "Machinist",
    "Manufacturing", "Meat Cutter", "Millwright", "Miscellaneous Services",
    "Operating Engineer", "Painting & Decoration", "Plasterer", "Plumbing & Pipefitting",
    "Fire Sprinkler Fitter", "Roofer", "Sheet Metal", "Sound / Communication Installer",
    "Surveyor", "Teamster", "Tile Layer/Setter"
  ];

  const COUNTIES = [
    "ALAMEDA", "ALPINE", "AMADOR", "BUTTE", "CALAVERAS", "COLUSA", "CONTRA COSTA",
    "DEL NORTE", "EL DORADO", "FRESNO", "GLENN", "HUMBOLDT", "IMPERIAL", "INYO",
    "KERN", "KINGS", "LAKE", "LASSEN", "LOS ANGELES", "MADERA", "MARIN", "MARIPOSA",
    "MENDOCINO", "MERCED", "MODOC", "MONO", "MONTEREY", "NAPA", "NEVADA", "ORANGE",
    "PLACER", "PLUMAS", "RIVERSIDE", "SACRAMENTO", "SAN BENITO", "SAN BERNARDINO",
    "SAN DIEGO", "SAN FRANCISCO", "SAN JOAQUIN", "SAN LUIS OBISPO", "SAN MATEO",
    "SANTA BARBARA", "SANTA CLARA", "SANTA CRUZ", "SHASTA", "SIERRA", "SISKIYOU",
    "SOLANO", "SONOMA", "STANISLAUS", "SUTTER", "TEHAMA", "TRINITY", "TULARE",
    "TUOLUMNE", "VENTURA", "YOLO", "YUBA"
  ];

  const handleAddOccupation = () => {
    if (occupations.length < 3) {
      setOccupations([...occupations, '']);
    }
  };

  const handleOccupationChange = (index, value) => {
    const newOccupations = [...occupations];
    newOccupations[index] = value;
    setOccupations(newOccupations);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsGenerating(true);
    setIsLoading(true);
    setError('');

    // Validate county against the COUNTIES array
    const validCounty = COUNTIES.find(c => c === county);
    if (!validCounty) {
      setError('Please select a valid county from the list');
      setIsLoading(false);
      setIsGenerating(false);
      return;
    }

    const payload = {
      project_id: "505267",
      occupations: occupations.filter(o => o !== ''),
      county: county, // Use the county value directly from the dropdown
    };

    console.log('=== Request Details ===');
    console.log('URL:', 'https://lcp2dir-907385054660.us-west1.run.app/DAS-140');
    console.log('Method:', 'POST');
    console.log('Headers:', {
      'Content-Type': 'application/json'
    });
    console.log('Payload:', JSON.stringify(payload, null, 2));

    try {
      const apiUrl = 'https://lcp2dir-907385054660.us-west1.run.app/DAS-140';
      
      console.log('\n=== Making API Request ===');
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      console.log('\n=== Response Details ===');
      console.log('Status:', response.status);
      console.log('Status Text:', response.statusText);

      if (response.ok) {
        console.log('Response OK - Initiating file download');
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'DAS-140-forms.zip');
        document.body.appendChild(link);
        link.click();
        link.remove();
        console.log('File download completed');
      } else {
        console.log('Response not OK - Handling error');
        const errorText = await response.text();
        let errorMessage;
        try {
          const errorData = JSON.parse(errorText);
          errorMessage = errorData.message || 'Unknown error occurred';
          console.error('API Error:', errorData);
        } catch (e) {
          errorMessage = errorText || 'Unknown error occurred';
          console.error('Parse Error:', e);
        }
        setError(`An error occurred while generating the forms: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Network Error:', error);
      setError(`An error occurred while generating the forms: ${error.message}`);
    } finally {
      console.log('\n=== Request Complete ===');
      setIsLoading(false);
      setIsGenerating(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-50 to-gray-100 text-gray-900">
      <Helmet>
        <title>DAS-140 Form Generator | Construct Regulate</title>
        <meta name="description" content="Automate DAS-140 form generation and submission for construction compliance. Save time and ensure accuracy with our AI-powered solution." />
        <meta name="keywords" content="DAS-140, construction compliance, form automation, AI-powered compliance, labor regulations" />
        <meta property="og:title" content="DAS-140 Form Generator | Construct Regulate" />
        <meta property="og:description" content="Streamline your DAS-140 form process with our AI-powered automation tool. Ensure compliance and save valuable time." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/das140-agent" />
        <meta property="og:image" content="https://constructregulate.com/images/das140-agent-og-image.jpg" />
      </Helmet>

      <div className="container mx-auto px-4 py-16">
        {/* Header Section */}
        <motion.header 
          className="text-center mb-16"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-5xl font-bold mb-4">
            Construct Regulate: AI-Powered Compliance Automation
          </h1>
          <p className="text-lg max-w-3xl mx-auto mb-8">
            Revolutionize how your construction company handles compliance, payroll, and administration. 
            Our AI-driven solutions automate tedious processes—without the need to migrate data or learn new tools.
          </p>
          <Button className="text-lg py-3 px-8" onClick={() => document.getElementById('das140-demo').scrollIntoView({ behavior: 'smooth' })}>
            Experience the Demo
          </Button>
        </motion.header>

        {/* AI Dream Team Section */}
        <section className="mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
            Meet Your AI Dream Team
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            <AgentCard 
              title="DAS-140 Form Generator"
              icon="📄"
              description="AI-powered generation of compliant DAS-140 forms."
              features={[
                "Instant form creation",
                "Multi-trade support",
                "Automated submission"
              ]}
              timeSaved="117 minutes"
            />
            <AgentCard 
              title="Compliance Tracker"
              icon="🔍"
              description="Real-time tracking of regulatory compliance."
              features={[
                "Deadline reminders",
                "Document archiving",
                "Audit preparation"
              ]}
              timeSaved="240 minutes"
            />
            <AgentCard 
              title="Payroll Automator"
              icon="💰"
              description="Streamlined payroll processing for construction projects."
              features={[
                "Prevailing wage calculations",
                "Certified payroll reports",
                "Tax withholding automation"
              ]}
              timeSaved="180 minutes"
            />
          </div>
        </section>

        {/* How It Works Section */}
        <section className="mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">
            How It Works
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {['Connect', 'Generate', 'Submit', 'Track'].map((step, index) => (
              <motion.div
                key={step}
                className="p-4 rounded-lg bg-white shadow-lg text-center"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="text-2xl font-bold mb-2">{index + 1}</div>
                <h3 className="text-xl font-semibold mb-2">{step}</h3>
                <p className="text-sm">{getStepDescription(step)}</p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Core Features Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6">Core Features of Construct Regulate</h2>
          <ul className="grid md:grid-cols-2 gap-6">
            {[
              'Real-Time Data Fetching: Pulls the latest apprentice and project data to ensure accuracy.',
              'Multi-Trade Support: Generate and submit forms for multiple trades simultaneously.',
              'Compliance Built-In: Forms are aligned with California DIR standards to avoid penalties.',
              'Intelligent Caching: Reduces API calls and speeds up responses.',
              'Flexible Output: Save forms locally, download them, or email them instantly.'
            ].map((feature, index) => (
              <motion.li 
                key={index} 
                className="bg-white p-5 rounded-lg shadow flex items-center"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <span className="text-blue-600 mr-2">✓</span> {feature}
              </motion.li>
            ))}
          </ul>
        </section>

        {/* Call to Action Section */}
        <section className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-6">Ready to Streamline Your Operations?</h2>
          <p className="mb-8 max-w-xl mx-auto">
            Don't let manual processes slow you down. Get started with Construct Regulate today and discover the power of AI automation.
          </p>
          <Button className="text-lg py-3 px-8" onClick={() => window.location.href = '/contact'}>
            Contact Us
          </Button>
        </section>

        {/* Updated Form Section */}
        <section id="das140-demo" className="mb-16">
          <h2 className="text-3xl font-bold mb-6 text-center">Generate DAS-140 Forms</h2>
          <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-lg">
            <div className="mb-6 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded" role="alert">
              <p className="font-bold">Demo Information</p>
              <p>This is a demonstration using an existing connection to retrieve the project (ID: 505267) from our database. The trade and county selections are live data inputs.</p>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projectId">
                  Project ID (Demo)
                </label>
                <input
                  id="projectId"
                  type="text"
                  value="505267"
                  disabled
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none bg-gray-100"
                />
              </div>
              
              {occupations.map((occupation, index) => (
                <div key={index}>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`occupation-${index}`}>
                    Occupation {index + 1}
                  </label>
                  <select
                    id={`occupation-${index}`}
                    value={occupation}
                    onChange={(e) => handleOccupationChange(index, e.target.value)}
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  >
                    <option value="">Select an occupation</option>
                    {TRADE_NAMES.map((name) => (
                      <option key={name} value={name}>{name}</option>
                    ))}
                  </select>
                </div>
              ))}
              
              {occupations.length < 3 && (
                <button
                  type="button"
                  onClick={handleAddOccupation}
                  className="text-blue-500 hover:text-blue-700 font-bold"
                >
                  + Add another occupation
                </button>
              )}
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="county">
                  County
                </label>
                <select
                  id="county"
                  value={county}
                  onChange={(e) => setCounty(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  required
                >
                  <option value="">Select a county</option>
                  {COUNTIES.map((countyName) => (
                    <option key={countyName} value={countyName}>{countyName}</option>
                  ))}
                </select>
              </div>
              
              {error && <p className="text-red-500 text-sm italic">{error}</p>}
              
              <Button
                type="submit"
                disabled={isLoading}
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:shadow-outline"
              >
                {isLoading ? 'Generating...' : 'Generate DAS-140 Forms'}
              </Button>
            </form>
          </div>
        </section>

        {/* Loading Animation */}
        <AnimatePresence>
          {isGenerating && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <div className="bg-white p-8 rounded-lg max-w-md w-full">
                <h3 className="text-2xl font-bold mb-4">Generating DAS-140 Forms</h3>
                <div className="mb-4">
                  <div className="h-2 bg-blue-200 rounded-full">
                    <motion.div
                      className="h-full bg-blue-500 rounded-full"
                      initial={{ width: "0%" }}
                      animate={{ width: "100%" }}
                      transition={{ duration: 27, ease: "linear" }}
                    />
                  </div>
                </div>
                <p className="text-gray-600">{generationSteps[generationStep]}</p>
                <div className="mt-4">
                  <h4 className="font-bold mb-2">Did you know?</h4>
                  <p className="text-sm text-gray-600">
                    {didYouKnowFacts[factIndex]}
                  </p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Additional Information Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-6 text-center">Why Choose Construct Regulate?</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Seamless Integration</h3>
              <p>Our solutions integrate with your current tools—no need for new systems or complicated migrations. Experience the power of AI-driven automation without disrupting your existing workflow.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Error-Free Operations</h3>
              <p>Eliminate manual errors with automated workflows and built-in validation. Ensure compliance and accuracy in every form submission, reducing the risk of costly fines and delays.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Time-Saving Automation</h3>
              <p>What could you do with an extra hour? Instead of spending time on manual form preparation, focus on managing projects, winning new contracts, or improving your operations.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Scalable Solutions</h3>
              <p>Whether you're handling a single project or multiple sites, our automation grows with your needs. Stay ahead of regulations with automated tracking, reporting, and submissions.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

function AgentCard({ title, icon, description, features, timeSaved }) {
  return (
    <motion.div 
      className="rounded-lg p-6 bg-white shadow-lg"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-2xl font-bold mb-3">{title}</h3>
      <p className="mb-4">{description}</p>
      <ul className="list-disc list-inside mb-4">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <div className="text-lg font-semibold">
        Time Saved: <span className="text-green-500">{timeSaved}</span>
      </div>
    </motion.div>
  );
}

function getStepDescription(step) {
  switch (step) {
    case 'Connect':
      return "Connect our API to your existing data sources.";
    case 'Generate':
      return "AI generates compliant DAS-140 forms instantly.";
    case 'Submit':
      return "Automatically submit forms to relevant committees.";
    case 'Track':
      return "Monitor compliance and maintain audit-ready records.";
    default:
      return "";
  }
}

export default DAS140Agent;
