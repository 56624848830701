import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaClock, FaFileAlt, FaCogs, FaNetworkWired, FaCheckCircle } from 'react-icons/fa';

const AIIntegration = () => {
  const solutions = [
    {
      icon: <FaClock className="text-blue-500 text-5xl mb-4" />,
      title: 'Mobile Timekeeping Solutions',
      description:
        'Eliminate the inefficiencies of manual timesheets. Our mobile apps allow workers to clock in and out easily, syncing data to payroll and HR systems in real-time.',
      benefits: [
        'Accuracy: Capture precise time entries.',
        'Convenience: Log time from any location.',
        'Real-Time Monitoring: Access labor data instantly.',
      ],
      features: [
        'Geo-fencing to ensure on-site clock-ins.',
        'Crew Management for team-wide time tracking.',
        'Compliance Tracking for overtime and labor laws.',
      ],
    },
    {
      icon: <FaFileAlt className="text-green-500 text-5xl mb-4" />,
      title: 'Digital Document Management',
      description:
        'Transform your document workflows with AI-powered tools. Securely organize, retrieve, and store blueprints, permits, and contracts in seconds.',
      benefits: [
        'Improved Accessibility: Access documents from any device.',
        'Reduced Paperwork: Minimize storage and environmental impact.',
        'Enhanced Collaboration: Share files with stakeholders easily.',
      ],
      features: [
        'Version Control for tracking document revisions.',
        'OCR for searchable scanned documents.',
        'Automated Workflows to streamline approvals.',
      ],
    },
    {
      icon: <FaCogs className="text-teal-500 text-5xl mb-4" />,
      title: 'IoT & Equipment Monitoring',
      description:
        'Leverage IoT sensors to track equipment usage, maintenance needs, and location in real-time. Our AI analyzes sensor data to maximize asset utilization.',
      benefits: [
        'Preventive Maintenance: Receive alerts before failures.',
        'Asset Tracking: Know where every machine is.',
        'Usage Optimization: Maximize equipment performance.',
      ],
      features: [
        'Environmental Monitoring for on-site conditions.',
        'Safety Alerts to detect unsafe operations.',
        'Fuel Consumption Analytics to reduce costs.',
      ],
    },
    {
      icon: <FaNetworkWired className="text-purple-500 text-5xl mb-4" />,
      title: 'Seamless Data Integration',
      description:
        'Integrate our AI tools with your ERP, HR, and project management systems. Maintain consistent data flow with no disruption.',
      benefits: [
        'Data Consistency: Ensure a single source of truth.',
        'Improved Decision-Making: Access detailed analytics.',
        'Reduced Manual Entry: Save time and reduce errors.',
      ],
      features: [
        'Project Budgeting for real-time financial tracking.',
        'Supply Chain Coordination with suppliers.',
        'Regulatory Compliance to meet industry standards.',
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <Helmet>
        <title>AI Integration & Automation Setup | Construct Regulate</title>
        <meta name="description" content="Seamlessly integrate AI-driven solutions into your existing systems. Minimize disruption and maximize productivity with our intelligent automation platform." />
        <meta name="keywords" content="AI integration, automation setup, construction technology, productivity optimization, workflow automation" />
        <meta property="og:title" content="AI Integration & Automation Setup | Construct Regulate" />
        <meta property="og:description" content="Transform your construction processes with our AI-driven automation solutions. Seamless integration, minimal disruption, maximum productivity." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/ai-integration" />
        <meta property="og:image" content="https://constructregulate.com/images/ai-integration-og-image.jpg" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative h-[75vh] bg-gradient-to-r from-cyan-500 to-blue-700 overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <motion.div
          className="absolute inset-0 flex flex-col items-center justify-center text-white text-center px-8 space-y-6"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-extrabold">AI Integration & Automation Setup</h1>
          <p className="text-lg max-w-2xl">
            Seamlessly integrate AI-driven solutions into your existing systems, minimizing disruption and maximizing productivity.
          </p>
          <button className="bg-white text-cyan-700 px-8 py-3 rounded-full text-xl font-medium hover:bg-gray-200 transition-all duration-300">
            Get a Free Consultation
          </button>
        </motion.div>
      </div>

      {/* Solutions Section */}
      <div className="container mx-auto px-6 py-16">
        <h2 className="text-4xl font-bold text-center mb-8">Our Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {solutions.map((solution, index) => (
            <motion.div
              key={index}
              className="p-8 shadow-lg rounded-lg bg-white"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              {solution.icon}
              <h3 className="text-2xl font-semibold mb-4">{solution.title}</h3>
              <p className="text-gray-700 mb-4">{solution.description}</p>
              <h4 className="text-lg font-bold mb-2">Benefits:</h4>
              <ul className="list-disc list-inside space-y-2 mb-4">
                {solution.benefits.map((benefit, idx) => (
                  <li key={idx} className="text-gray-700">{benefit}</li>
                ))}
              </ul>
              <h4 className="text-lg font-bold mb-2">Construction-Specific Features:</h4>
              <ul className="list-disc list-inside space-y-2">
                {solution.features.map((feature, idx) => (
                  <li key={idx} className="text-gray-700">{feature}</li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-gradient-to-r from-teal-500 to-blue-600 text-white py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-bold mb-6">Ready to Maximize Productivity?</h2>
          <p className="text-xl mb-8">
            Discover how seamless AI integration can transform your business.
          </p>
          <button className="bg-white text-teal-700 px-8 py-3 rounded-full text-xl font-medium hover:bg-gray-200 transition-all duration-300">
            Get a Free Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default AIIntegration;
