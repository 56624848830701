import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { 
    CheckCircle, 
    ClipboardList, 
    Shield, 
    FileText,
    Clock,
    DollarSign,
    AlertTriangle,
    Award,
    ArrowRight,
    Building,
    Users,
    Wrench
  } from 'lucide-react';

const ComplianceAutomation = () => {
  const solutions = [
    {
      icon: <ClipboardList className="w-12 h-12 text-blue-500" />,
      title: 'DAS-140 Filing Automation',
      description: 'Transform your DAS-140 form filing process with our intelligent automation system. Our AI-powered solution handles everything from data collection to submission, ensuring perfect compliance with California labor regulations.',
      benefits: [
        'Save up to 2 hours per batch of 20 forms with automated processing',
        'Eliminate manual errors with smart form validation',
        'Automatic email delivery to appropriate committees',
        'Built-in compliance with DIR standards and regulations',
        'Real-time tracking and archiving of all submissions'
      ],
      features: [
        'Intelligent data extraction from existing systems',
        'Automated form generation and validation',
        'Digital signature integration',
        'Automated committee identification',
        'Comprehensive audit trail'
      ]
    },
    {
      icon: <Shield className="w-12 h-12 text-green-500" />,
      title: 'Safety Monitoring Automation',
      description: 'Protect your workforce and ensure OSHA compliance with our AI-driven safety monitoring system. Real-time hazard detection and automated reporting keep your sites safe and compliant.',
      benefits: [
        'Reduce incident rates by up to 50% with proactive monitoring',
        'Automatic generation of safety reports and documentation',
        'Real-time alerts for potential hazards',
        'Streamlined OSHA compliance reporting',
        'Comprehensive safety analytics dashboard'
      ],
      features: [
        'IoT sensor integration for real-time monitoring',
        'AI-powered hazard detection algorithms',
        'Automated safety report generation',
        'Mobile alerts and notifications',
        'Historical data analysis and trending'
      ]
    },
    {
      icon: <FileText className="w-12 h-12 text-purple-500" />,
      title: 'Document Compliance Management',
      description: 'Streamline your document management with our intelligent automation platform. From permits to inspections, ensure every document is properly filed, tracked, and updated.',
      benefits: [
        'Centralized repository for all compliance documents',
        'Automated deadline tracking and reminders',
        'Simple audit preparation with organized documentation',
        'Version control and document history tracking',
        'Reduced risk of missing or incomplete documentation'
      ],
      features: [
        'Smart document categorization',
        'Automated expiration tracking',
        'OCR technology for document digitization',
        'Secure cloud storage and backup',
        'Advanced search and retrieval'
      ]
    }
  ];

  const processSteps = [
    {
      icon: <Building className="w-8 h-8 text-blue-500" />,
      title: 'Initial Assessment',
      description: 'We analyze your current compliance processes and identify opportunities for automation and improvement.'
    },
    {
      icon: <Wrench className="w-8 h-8 text-green-500" />, // Changed from Tool to Wrench
      title: 'Custom Implementation',
      description: 'Our team configures the automation solutions to work seamlessly with your existing systems and workflows.'
    },
    {
      icon: <Users className="w-8 h-8 text-purple-500" />,
      title: 'Team Training',
      description: 'We provide comprehensive training to ensure your team can maximize the benefits of our automation tools.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Compliance Automation for Construction | Construct Regulate</title>
        <meta name="description" content="Revolutionize your construction compliance processes with AI-powered automation. Save time, eliminate errors, and stay ahead of complex regulations." />
        <meta name="keywords" content="compliance automation, construction regulations, AI-powered compliance, DAS-140 filing, safety monitoring, document management" />
        <meta property="og:title" content="Compliance Automation for Construction | Construct Regulate" />
        <meta property="og:description" content="Transform construction compliance with AI automation. Streamline DAS-140 filing, enhance safety monitoring, and simplify document management." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/compliance-automation" />
        <meta property="og:image" content="https://constructregulate.com/images/compliance-automation-og-image.jpg" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Transform Construction Compliance with AI Automation
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Revolutionize your construction compliance processes with our advanced AI-powered automation solutions. Save valuable time, eliminate errors, and stay ahead of complex regulations with Construct Regulate's intelligent systems.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                Schedule Demo
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Learn More
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Intelligent Compliance Automation for Modern Construction
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              At Construct Regulate, we understand the complexities of construction compliance. Our AI-powered solutions are designed specifically for small and mid-sized construction companies, offering enterprise-level automation without the need for extensive system changes or complex implementations.
            </p>
            
            <div className="grid md:grid-cols-3 gap-8">
              <motion.div 
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <div className="h-12 w-12 bg-blue-500 rounded-xl mb-6 flex items-center justify-center">
                  <CheckCircle className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-bold mb-4 text-blue-900">Seamless Integration</h3>
                <p className="text-gray-600">
                  Works with your existing tools and processes - no complex migrations needed
                </p>
              </motion.div>

              <motion.div 
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <div className="h-12 w-12 bg-purple-500 rounded-xl mb-6 flex items-center justify-center">
                  <CheckCircle className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-bold mb-4 text-blue-900">Rapid Implementation</h3>
                <p className="text-gray-600">
                  Get up and running in days, not months, with our streamlined setup process
                </p>
              </motion.div>

              <motion.div 
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <div className="h-12 w-12 bg-indigo-500 rounded-xl mb-6 flex items-center justify-center">
                  <CheckCircle className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-bold mb-4 text-blue-900">Proven Results</h3>
                <p className="text-gray-600">
                  Join hundreds of construction companies already saving time and reducing errors
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Detailed Solutions Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Comprehensive Automation Solutions</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our suite of AI-powered tools transforms complex compliance tasks into streamlined, automated processes that save time and reduce errors.
            </p>
          </motion.div>

          <div className="space-y-12">
            {solutions.map((solution, index) => (
              <motion.div
                key={solution.title}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <div className="flex items-center gap-4 mb-6">
                      {solution.icon}
                      <h3 className="text-2xl font-bold">{solution.title}</h3>
                    </div>
                    <p className="text-gray-600 mb-6">{solution.description}</p>
                    <h4 className="font-semibold text-lg mb-4">Key Benefits:</h4>
                    <ul className="space-y-3 mb-6">
                      {solution.benefits.map((benefit, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-lg mb-4">Features:</h4>
                    <ul className="space-y-3">
                      {solution.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Implementation Process */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Simple Implementation Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Get started quickly with our streamlined implementation process, designed to minimize disruption to your operations.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {processSteps.map((step, index) => (
              <motion.div
                key={index}
                className="text-center p-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="flex justify-center mb-4">{step.icon}</div>
                <h3 className="text-xl font-bold mb-3">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Compliance Process?</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Join the growing number of construction companies that are streamlining their operations with our AI-powered solutions. Schedule a demo today and see how we can help your business save time and reduce compliance risks.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule Your Free Demo
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceAutomation;
