import React from 'react';
import { motion } from 'framer-motion';
import { 
  LayoutGrid,
  ClipboardList, 
  Users, 
  LineChart,
  Link,
  Brain,
  Eye,
  CheckCircle,
  ArrowRight,
  Timer,
  DollarSign,
  Smile,
  Shield,
  Zap
} from 'lucide-react';

const ProjectManagement = () => {
  const features = [
    {
      icon: <LayoutGrid className="w-12 h-12 text-blue-500" />,
      title: 'Streamlined Project Workflows',
      description: 'AI-powered workflow optimization that ensures every team member knows what needs to be done, when, and how.',
      benefits: [
        'Reduce planning and administrative time',
        'Standardize processes across projects',
        'Adapt quickly to project changes'
      ],
      techDetails: [
        'Machine learning workflow design',
        'Dynamic real-time adjustments',
        'Proactive bottleneck identification'
      ]
    },
    {
      icon: <Users className="w-12 h-12 text-green-500" />,
      title: 'Automated Task Assignments',
      description: 'Intelligent resource allocation based on skills, availability, and workload for optimal team utilization.',
      benefits: [
        'Match tasks to team member strengths',
        'Prevent oversight and delays',
        'Balance workloads effectively'
      ],
      techDetails: [
        'Advanced skill matching algorithms',
        'Intelligent workload balancing',
        'Predictive resource scheduling'
      ]
    },
    {
      icon: <LineChart className="w-12 h-12 text-purple-500" />,
      title: 'Real-Time Progress Tracking',
      description: 'Complete visibility into project status with continuous data collection and analysis.',
      benefits: [
        'Make informed decisions quickly',
        'Maintain stakeholder transparency',
        'Identify and mitigate risks early'
      ],
      techDetails: [
        'Multi-source data integration',
        'Interactive KPI dashboards',
        'Predictive analytics engine'
      ]
    },
    {
      icon: <Link className="w-12 h-12 text-indigo-500" />,
      title: 'Enhanced Collaboration',
      description: 'Seamless communication tools that connect teams, subcontractors, clients, and stakeholders.',
      benefits: [
        'Reduce miscommunication',
        'Speed up issue resolution',
        'Improve document accessibility'
      ],
      techDetails: [
        'Integrated communication platform',
        'Smart notification system',
        'Centralized document management'
      ]
    }
  ];

  const aiCapabilities = [
    {
      icon: <Brain className="w-12 h-12 text-rose-500" />,
      title: 'Natural Language Processing',
      description: 'Understand and interpret unstructured data from emails, meetings, and reports.',
      capabilities: [
        'Automated data extraction',
        'Sentiment analysis',
        'Communication pattern recognition'
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-amber-500" />,
      title: 'Machine Learning',
      description: 'Learn from historical data to improve predictions and optimize processes.',
      capabilities: [
        'Risk prediction models',
        'Schedule optimization',
        'Pattern recognition'
      ]
    },
    {
      icon: <Eye className="w-12 h-12 text-cyan-500" />,
      title: 'Computer Vision',
      description: 'Visual monitoring and analysis of construction progress and safety.',
      capabilities: [
        'Progress verification',
        'Safety compliance monitoring',
        'Visual documentation'
      ]
    }
  ];

  const stats = [
    {
      icon: <Timer className="w-8 h-8 text-blue-500" />,
      value: "20",
      unit: "%",
      label: "Reduction in Project Delays"
    },
    {
      icon: <DollarSign className="w-8 h-8 text-green-500" />,
      value: "15",
      unit: "%",
      label: "Cost Savings"
    },
    {
      icon: <Smile className="w-8 h-8 text-purple-500" />,
      value: "200",
      unit: "%",
      label: "ROI in First Year"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              AI-Powered Project Management
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Revolutionize your construction project management with AI agents that streamline workflows, automate tasks, and provide real-time insights for flawless project execution.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                Schedule Demo
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Learn More
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Transform Project Management with AI
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              Traditional project management methods often struggle with manual task allocation, delayed communication, and inefficient workflow management. Our AI-driven solutions address these challenges head-on.
            </p>
            
            {/* Stats Section */}
            <div className="grid md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex justify-center mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-blue-900 mb-2">
                    {stat.value}<span className="text-blue-500">{stat.unit}</span>
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Key Features</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our comprehensive suite of AI-powered features transforms every aspect of project management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="mb-6">{feature.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.description}</p>
                
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Benefits</h4>
                    <ul className="space-y-3">
                      {feature.benefits.map((benefit, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Technical Details</h4>
                    <ul className="space-y-3">
                      {feature.techDetails.map((detail, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{detail}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* AI Capabilities Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Advanced AI Capabilities</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Powered by cutting-edge artificial intelligence technologies that enhance every aspect of project management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {aiCapabilities.map((capability, index) => (
              <motion.div
                key={capability.title}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5 }}
              >
                <div className="mb-6">{capability.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{capability.title}</h3>
                <p className="text-gray-600 mb-6">{capability.description}</p>
                <ul className="space-y-3">
                  {capability.capabilities.map((item, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Case Study Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-white rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">Success Story: Large-Scale Commercial Development</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-xl mb-2">Challenge:</h3>
                <p className="text-gray-600">
                  A construction company undertaking a large commercial project faced challenges in coordinating multiple subcontractors, managing tight deadlines, and keeping stakeholders informed.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Solution:</h3>
                <p className="text-gray-600">
                  We implemented AI agents for workflow optimization, automated task assignments, and real-time tracking with comprehensive reporting dashboards.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Results:</h3>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">20% reduction in project delays</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">15% cost savings through optimized resource utilization</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">200% ROI in the first year</span>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

{/* CTA Section (continued) */}
<div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Project Management?</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Experience the power of AI-driven project management. Our solutions help you deliver projects on time, within budget, while enhancing collaboration across teams.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule Your Free Demo
            </button>
          </motion.div>
        </div>
      </div>

      {/* Implementation Process */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Implementation Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our structured approach ensures a smooth transition to AI-powered project management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                step: "1",
                title: "Needs Assessment",
                description: "We analyze your current processes and identify opportunities for AI implementation.",
                icon: <ClipboardList className="w-8 h-8 text-blue-500" />
              },
              {
                step: "2",
                title: "Solution Design",
                description: "Customize AI solutions to match your specific workflows and requirements.",
                icon: <LayoutGrid className="w-8 h-8 text-green-500" />
              },
              {
                step: "3",
                title: "Deployment",
                description: "Implement solutions in phases to minimize disruption to ongoing projects.",
                icon: <Zap className="w-8 h-8 text-purple-500" />
              },
              {
                step: "4",
                title: "Optimization",
                description: "Continuously monitor and refine the system based on performance data.",
                icon: <LineChart className="w-8 h-8 text-indigo-500" />
              }
            ].map((phase, index) => (
              <motion.div
                key={phase.step}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mx-auto mb-6 shadow-md">
                  <span className="text-blue-600 font-bold text-xl">{phase.step}</span>
                </div>
                <div className="flex justify-center mb-4">{phase.icon}</div>
                <h3 className="text-xl font-bold mb-3">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Security & Compliance */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold mb-4">Security & Compliance</h2>
              <p className="text-gray-600">
                Your data security is our top priority. Our solutions are built with enterprise-grade security features.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Data Encryption",
                  description: "End-to-end encryption for all data in transit and at rest",
                  icon: <Shield className="w-8 h-8 text-blue-500" />
                },
                {
                  title: "Access Controls",
                  description: "Role-based access control with detailed audit logs",
                  icon: <Users className="w-8 h-8 text-green-500" />
                },
                {
                  title: "Compliance",
                  description: "Adherence to ISO 27001 and GDPR standards",
                  icon: <CheckCircle className="w-8 h-8 text-purple-500" />
                }
              ].map((item, index) => (
                <motion.div
                  key={item.title}
                  className="bg-white rounded-xl shadow-lg p-6 text-center"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex justify-center mb-4">{item.icon}</div>
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "How long does implementation take?",
                  answer: "Implementation typically takes 4-12 weeks, depending on project scope and complexity."
                },
                {
                  question: "Will our team need extensive training?",
                  answer: "We provide comprehensive training tailored to your team's needs, ensuring a smooth transition."
                },
                {
                  question: "Can it integrate with our existing software?",
                  answer: "Yes, our solutions integrate seamlessly with popular project management tools and can be customized for proprietary systems."
                },
                {
                  question: "How does AI handle project unpredictability?",
                  answer: "Our AI systems use real-time data and predictive analytics to adapt to changes dynamically, providing recommendations for optimal outcomes."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-xl p-6"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <h3 className="text-xl font-bold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManagement;