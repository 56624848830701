import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';

function Header() {
  const { isDarkMode, toggleDarkMode, currentStyle } = useContext(ThemeContext);
  const isConstructFuture = currentStyle === 'Construct Future';

  return (
    <header className={`p-4 ${isDarkMode ? 'bg-gray-900 bg-opacity-80' : 'bg-white bg-opacity-80'} shadow-md sticky top-0 z-50`}>
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className={`text-2xl font-bold ${isConstructFuture ? 'font-construct-heading' : 'font-industrial-heading'}`}>
          Construct Regulate
        </Link>
        <nav>
          <ul className="flex space-x-6">
            <li><NavLink to="/" className={({isActive}) => `hover:text-blue-600 transition duration-300 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} ${isActive ? 'font-bold' : ''}`}>Home</NavLink></li>
            <li><NavLink to="/services" className={({isActive}) => `hover:text-blue-600 transition duration-300 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} ${isActive ? 'font-bold' : ''}`}>Services</NavLink></li>
            <li><NavLink to="/agents" className={({isActive}) => `hover:text-blue-600 transition duration-300 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} ${isActive ? 'font-bold' : ''}`}>Agents</NavLink></li>
            <li><NavLink to="/about" className={({isActive}) => `hover:text-blue-600 transition duration-300 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} ${isActive ? 'font-bold' : ''}`}>About</NavLink></li>
            <li><NavLink to="/contact" className={({isActive}) => `hover:text-blue-600 transition duration-300 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} ${isActive ? 'font-bold' : ''}`}>Contact</NavLink></li>
          </ul>
        </nav>
        <button 
          onClick={toggleDarkMode} 
          className={`px-4 py-2 rounded ${isDarkMode ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-900'} transition duration-300`}
        >
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>
    </header>
  );
}

export default Header;
