import React from 'react';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Helmet } from 'react-helmet';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ServicesPage() {
  const automationData = {
    labels: ['Project Management', 'Financial Management', 'Resource Allocation', 'Quality Control', 'Safety Monitoring'],
    datasets: [
      {
        label: 'Automation Percentage',
        data: [75, 80, 70, 65, 60],
        backgroundColor: 'rgba(255, 127, 0, 0.6)',
        borderColor: 'rgba(255, 127, 0, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Automation Percentages by Role',
      },
    },
  };

  return (
    <div className="services-page dark:bg-gray-900 dark:text-white">
      <Helmet>
        <title>AI Agent Automation Services | Construct Regulate</title>
        <meta name="description" content="Discover our AI agent automation services for the construction industry. Streamline project management, financial management, resource allocation, and more." />
        <meta name="keywords" content="AI agents, construction automation, project management, financial management, resource allocation, quality control, safety monitoring" />
        <meta property="og:title" content="AI Agent Automation Services | Construct Regulate" />
        <meta property="og:description" content="Revolutionize your construction processes with intelligent automation. Explore our AI agent services for improved efficiency and productivity." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/services" />
        <meta property="og:image" content="https://constructregulate.com/images/services-og-image.jpg" />
      </Helmet>

      <section className="hero bg-light-gray dark:bg-gray-800 py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold mb-4 font-heading">Our AI Agent Automation Services</h1>
          <p className="text-xl mb-8">Revolutionizing construction processes with intelligent automation</p>
        </div>
      </section>

      <section className="services-list py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center font-heading">How We Can Help</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <ServiceCard 
              title="Project Management"
              description="Our AI agents streamline project workflows, automate task assignments, and provide real-time progress tracking and reporting. This leads to improved collaboration and on-time project delivery."
              link="/services/project-management"
            />
            <ServiceCard 
              title="Financial Management"
              description="Automate invoicing, budgeting, and financial forecasting with our AI-powered financial management tools. Reduce errors and improve cash flow management."
              link="/services/financial-management"
            />
            <ServiceCard 
              title="Resource Allocation"
              description="Optimize workforce and equipment distribution across projects with our AI-driven resource allocation system. Maximize efficiency and reduce downtime."
              link="/services/resource-allocation"
            />
            <ServiceCard 
              title="Quality Control"
              description="Implement AI-powered quality control measures that can detect issues early, ensure compliance with standards, and maintain consistent quality across all projects."
              link="/services/quality-control"
            />
            <ServiceCard 
              title="Safety Monitoring"
              description="Enhance on-site safety with AI agents that can monitor for potential hazards, ensure compliance with safety protocols, and provide real-time alerts to prevent accidents."
              link="/services/safety-monitoring"
            />
            <ServiceCard 
              title="Predictive Maintenance"
              description="Utilize AI to predict equipment maintenance needs, reducing unexpected downtime and extending the life of your valuable machinery."
              link="/services/predictive-maintenance"
            />
          </div>
        </div>
      </section>

      <section className="industries-served py-16 bg-light-gray dark:bg-gray-800">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center font-heading">Industries We Serve</h2>
          <ul className="grid grid-cols-2 md:grid-cols-3 gap-4 text-center">
            <li className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">Residential Construction</li>
            <li className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">Commercial Building</li>
            <li className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">Infrastructure Development</li>
            <li className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">Industrial Construction</li>
            <li className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">Renovation and Remodeling</li>
            <li className="bg-white dark:bg-gray-700 p-4 rounded-lg shadow">Specialty Contracting</li>
          </ul>
        </div>
      </section>

      <section className="automation-chart py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center font-heading">Automation Percentages by Role</h2>
          <div className="max-w-3xl mx-auto">
            <Bar data={automationData} options={chartOptions} />
          </div>
        </div>
      </section>
    </div>
  );
}

function ServiceCard({ title, description, link }) {
  return (
    <div className="card hover-card">
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
      <Link to={link} className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
        Learn More
      </Link>
    </div>
  );
}

export default ServicesPage;
