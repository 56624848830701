import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip, ResponsiveContainer
} from 'recharts';
import {
  FileCheck,
  Users,
  Mail,
  Clock,
  CheckCircle,
  XCircle,
  FileText,
  Send,
  Download,
  Upload,
  Calendar,
  Briefcase,
  AlertCircle
} from 'lucide-react';

const LaborComplianceMonitoring = () => {
  const [selectedWeek, setSelectedWeek] = useState('07/01/2024 - 07/06/2024');

  // Weekly Labor Ratio Data
  const weeklyRatioData = {
    trades: ['Carpenter', 'Laborer', 'Cement Mason'],
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    compliance: {
      Carpenter: [true, true, true, null, null, null],
      Laborer: [true, true, true, null, null, null],
      'Cement Mason': [null, null, null, null, null, null]
    }
  };

  return (
    <div className="space-y-8">
      {/* Weekly Labor Ratio Report */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Weekly Labor Ratio Report</h2>
            <p className="text-sm text-gray-500 mt-1">Apprentice to Journeyman Ratio Monitoring</p>
          </div>
          <div className="flex gap-4">
            <select 
              className="border rounded-lg px-4 py-2"
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(e.target.value)}
            >
              <option>{selectedWeek}</option>
              {/* Add more weeks */}
            </select>
          </div>
        </div>

        <div className="border rounded-lg overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900 w-40">Trade</th>
                {weeklyRatioData.days.map(day => (
                  <th key={day} className="px-6 py-3 text-center text-sm font-semibold text-gray-900">
                    {day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {weeklyRatioData.trades.map(trade => (
                <tr key={trade} className="border-t">
                  <td className="px-6 py-4 text-sm font-medium text-gray-900 bg-gray-50">
                    {trade}
                  </td>
                  {weeklyRatioData.compliance[trade].map((isCompliant, index) => (
                    <td key={index} className="px-6 py-4 text-center">
                      {isCompliant === true && (
                        <CheckCircle className="w-6 h-6 text-green-500 mx-auto" />
                      )}
                      {isCompliant === false && (
                        <XCircle className="w-6 h-6 text-red-500 mx-auto" />
                      )}
                      {isCompliant === null && (
                        <span className="text-gray-400">-</span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex gap-6 text-sm text-gray-600">
          <div className="flex items-center gap-2">
            <CheckCircle className="w-4 h-4 text-green-500" />
            <span>Ratio ≤ 1:1</span>
          </div>
          <div className="flex items-center gap-2">
            <XCircle className="w-4 h-4 text-red-500" />
            <span>Ratio {'>'} 1:1</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-400">-</span>
            <span>No hours recorded</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkilledAndTrainedReport = () => {
  // Skilled and Trained Workforce Data
  const skilledTrainedData = [
    {
      trade: 'Carpenter',
      requiredPercentage: 30,
      graduateCount: 73,
      experienceCount: 5,
      ratioCount: '93.59%',
      graduateHours: 2368.92,
      experienceHours: 107.50,
      ratioHours: '95.66%'
    },
    {
      trade: 'Cement Mason',
      requiredPercentage: 30,
      graduateCount: 10,
      experienceCount: 23,
      ratioCount: '30.30%',
      graduateHours: 167.50,
      experienceHours: 347.50,
      ratioHours: '32.52%'
    },
    {
      trade: 'Laborer',
      requiredPercentage: 60,
      graduateCount: 14,
      experienceCount: 9,
      ratioCount: '60.86%',
      graduateHours: 361.50,
      experienceHours: 329.5,
      ratioHours: '52.32%'
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Skilled and Trained Workforce Report</h2>
          <p className="text-sm text-gray-500 mt-1">Monthly Compliance Tracking</p>
        </div>
      </div>

      <div className="border rounded-lg overflow-x-auto">
        <table className="w-full min-w-[1000px]">
          <thead>
            <tr className="bg-red-600 text-white">
              <th className="px-4 py-3 text-left text-sm font-semibold">
                Trade (Apprenticeable Occupation)
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                Required minimum SJ: Apprentice Graduate %
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                SJ: Apprentice Graduate (count)
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                SJ: On-The-Job Experience (count)
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                SJ ratio (count)
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                SJ: Apprentice Graduate (hours)
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                SJ: On-The-Job Experience (hours)
              </th>
              <th className="px-4 py-3 text-center text-sm font-semibold">
                SJ ratio (hours)
              </th>
            </tr>
          </thead>
          <tbody>
            {skilledTrainedData.map((row, index) => (
              <tr key={row.trade} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="px-4 py-3 text-sm font-medium text-gray-900">
                  {row.trade}
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  {row.requiredPercentage}%
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  {row.graduateCount}
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  {row.experienceCount}
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  <span className={
                    parseFloat(row.ratioCount) >= row.requiredPercentage 
                      ? 'text-green-600 font-semibold' 
                      : 'text-red-600 font-semibold'
                  }>
                    {row.ratioCount}
                  </span>
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  {row.graduateHours}
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  {row.experienceHours}
                </td>
                <td className="px-4 py-3 text-center text-sm text-gray-900">
                  <span className={
                    parseFloat(row.ratioHours) >= row.requiredPercentage 
                      ? 'text-green-600 font-semibold' 
                      : 'text-red-600 font-semibold'
                  }>
                    {row.ratioHours}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PaymentComplianceDashboard = () => {
  const [activeTab, setActiveTab] = useState('laborCompliance');
  const [selectedProject, setSelectedProject] = useState('Project B');
  
  // Lien Release Overview Data
  const lienReleaseData = {
    thisMonth: {
      conditional: {
        sent: 35,
        received: 22,
        pending: 13
      },
      unconditional: {
        sent: 45,
        received: 32,
        pending: 13
      }
    },
    trends: [
      { month: 'Jan', onTime: 92, late: 8 },
      { month: 'Feb', onTime: 88, late: 12 },
      { month: 'Mar', onTime: 95, late: 5 },
      { month: 'Apr', onTime: 90, late: 10 },
      { month: 'May', onTime: 93, late: 7 }
    ]
  };

  // Quick Action Button Component
  const QuickActionButton = ({ icon: Icon, label, onClick, status }) => (
    <button
      onClick={onClick}
      className={`flex items-center gap-3 p-4 rounded-lg w-full transition-all
        ${status === 'completed' ? 'bg-green-50 text-green-700' :
          status === 'pending' ? 'bg-yellow-50 text-yellow-700' :
          'bg-blue-50 text-blue-700'}`}
    >
      <Icon className="w-5 h-5" />
      <span className="font-medium">{label}</span>
      {status === 'completed' && <CheckCircle className="w-4 h-4 ml-auto" />}
      {status === 'pending' && <Clock className="w-4 h-4 ml-auto" />}
    </button>
  );

  const VendorLienStatus = () => (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-semibold">Vendor Lien Status</h3>
        <button className="text-blue-500 hover:text-blue-700 flex items-center gap-2">
          <Mail className="w-4 h-4" />
          Send Reminders
        </button>
      </div>
      
      <div className="space-y-4">
        {[
          { vendor: 'Steel Supply Co', amount: 85000, conditional: true, unconditional: false },
          { vendor: 'Concrete Masters', amount: 65000, conditional: true, unconditional: true },
          { vendor: 'Equipment Rental Inc', amount: 45000, conditional: false, unconditional: false }
        ].map((vendor, index) => (
          <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div>
              <p className="font-medium">{vendor.vendor}</p>
              <p className="text-sm text-gray-500">${vendor.amount.toLocaleString()}</p>
            </div>
            <div className="flex gap-4">
              <span className={`px-3 py-1 rounded-full text-sm ${
                vendor.conditional ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              }`}>
                Conditional
              </span>
              <span className={`px-3 py-1 rounded-full text-sm ${
                vendor.unconditional ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              }`}>
                Unconditional
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const DownloadSection = () => (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <h3 className="text-lg font-semibold mb-4">Download Reports</h3>
      <div className="space-y-3">
        {[
          { title: 'Union Trust Report', icon: FileCheck, type: 'Monthly' },
          { title: 'Lien Release Package', icon: Download, type: 'Current' },
          { title: 'Compliance Summary', icon: AlertCircle, type: 'Weekly' }
        ].map((report, index) => (
          <button 
            key={index}
            className="w-full flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-center gap-3">
              <report.icon className="w-5 h-5 text-blue-500" />
              <span className="font-medium">{report.title}</span>
            </div>
            <span className="text-sm text-gray-500">{report.type}</span>
          </button>
        ))}
      </div>
    </div>
  );

  const LienTrendsChart = () => (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <h3 className="text-lg font-semibold mb-4">Lien Release Trends</h3>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={lienReleaseData.trends}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="onTime" 
              stroke="#10B981" 
              name="On Time"
            />
            <Line 
              type="monotone" 
              dataKey="late" 
              stroke="#EF4444" 
              name="Late"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="bg-white border-b">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Payment Requirements Dashboard</h1>
              <p className="text-sm text-gray-500 mt-1">
                Automated Compliance Tracking & Processing
              </p>
            </div>
            <div className="flex items-center gap-4">
              <select 
                className="border rounded-lg px-4 py-2"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                <option>Project A</option>
                <option>Project B</option>
                <option>Project C</option>
              </select>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center gap-2">
                <Send className="w-4 h-4" />
                Generate Payment Package
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 py-8">
        <div className="grid grid-cols-12 gap-6">
          {/* Status Overview Cards */}
          <div className="col-span-12 grid grid-cols-4 gap-6">
            {/* Lien Releases Card */}
            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="flex items-center justify-between mb-4">
                <FileCheck className="w-8 h-8 text-blue-500" />
                <span className="px-2 py-1 rounded-full text-sm bg-yellow-100 text-yellow-800">
                  Pending
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Lien Releases</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500">Required</span>
                  <span className="font-medium">45</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">Received</span>
                  <span className="font-medium">32</span>
                </div>
                <div className="flex justify-between text-yellow-600">
                  <span>Pending</span>
                  <span className="font-medium">13</span>
                </div>
              </div>
            </div>

            {/* Labor Compliance Card */}
            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="flex items-center justify-between mb-4">
                <Users className="w-8 h-8 text-purple-500" />
                <span className="px-2 py-1 rounded-full text-sm bg-yellow-100 text-yellow-800">
                  Pending
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Labor Compliance</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500">LCPTracker</span>
                  <CheckCircle className="w-5 h-5 text-green-500" />
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">DIR Submission</span>
                  <CheckCircle className="w-5 h-5 text-green-500" />
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">Union Trust</span>
                  <Clock className="w-5 h-5 text-yellow-500" />
                </div>
              </div>
            </div>

            {/* Skilled & Trained Card */}
            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="flex items-center justify-between mb-4">
                <Briefcase className="w-8 h-8 text-green-500" />
                <span className="px-2 py-1 rounded-full text-sm bg-red-100 text-red-800">
                  Non-Compliant
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Skilled & Trained</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500">carpenter</span>
                  <span className="font-medium text-green-600">93.6%</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">cementMason</span>
                  <span className="font-medium text-green-600">32.5%</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">laborer</span>
                  <span className="font-medium text-red-600">52.3%</span>
                </div>
              </div>
            </div>

            {/* Payment Status Card */}
            <div className="bg-white rounded-xl p-6 shadow-lg">
              <div className="flex items-center justify-between mb-4">
                <Calendar className="w-8 h-8 text-red-500" />
                <span className="px-2 py-1 rounded-full text-sm bg-red-100 text-red-800">
                  Action Required
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Payment Status</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500">Due Date</span>
                  <span className="font-medium">Oct 30, 2024</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">Amount</span>
                  <span className="font-medium">$245,780</span>
                </div>
                <div className="flex justify-between text-red-600">
                  <span>Missing Items</span>
                  <span className="font-medium">3</span>
                </div>
              </div>
            </div>
          </div>

          {/* Quick Actions and Main Content Area */}
          <div className="col-span-3">
            <h3 className="text-lg font-bold text-gray-900 mb-4">Quick Actions</h3>
            <div className="bg-white rounded-xl p-4 shadow-lg space-y-3">
              <QuickActionButton
                icon={Mail}
                label="Request Missing Lien Releases"
                status="pending"
                onClick={() => {}}
              />
              <QuickActionButton
                icon={Upload}
                label="Upload Weekly Certified Payroll"
                status="completed"
                onClick={() => {}}
              />
              <QuickActionButton
                icon={FileText}
                label="Generate Compliance Report"
                onClick={() => {}}
              />
              <QuickActionButton
                icon={Download}
                label="Download Payment Package"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="col-span-9">
            {/* Tabs */}
            <div className="flex gap-4 mb-6">
              {[
                { id: 'overview', label: 'Overview' },
                { id: 'lienReleases', label: 'Lien Releases' },
                { id: 'laborCompliance', label: 'Labor Compliance' },
                { id: 'skilledTrained', label: 'Skilled & Trained' }
              ].map(tab => (
                <button
                  key={tab.id}
                  className={`px-4 py-2 rounded-lg font-medium ${
                    activeTab === tab.id ?
                    'bg-blue-500 text-white' :
                    'bg-white text-gray-600 hover:bg-gray-50'
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            {/* Content Area */}
            <div className="bg-white rounded-xl p-6 shadow-lg">
              {activeTab === 'overview' && (
                <div className="grid grid-cols-2 gap-6">
                  <VendorLienStatus />
                  <LienTrendsChart />
                  <DownloadSection />
                </div>
              )}
              {activeTab === 'lienReleases' && (
                <div className="grid grid-cols-2 gap-6">
                  <VendorLienStatus />
                  <LienTrendsChart />
                </div>
              )}
              {activeTab === 'laborCompliance' && (
                <LaborComplianceMonitoring />
              )}
              {activeTab === 'skilledTrained' && (
                <SkilledAndTrainedReport />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentComplianceDashboard;
