import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Button from '../components/Button';

function AboutUs() {
  return (
    <div className="about-us-page dark:bg-gray-900 dark:text-white">
      <HeroSection />
      <MissionVision />
      <Team />
      <History />
      <CTA />
    </div>
  );
}

function HeroSection() {
  return (
    <section className="hero bg-light-gray py-20">
      <div className="container mx-auto text-center">
        <motion.h1 
          className="text-4xl font-bold mb-4 font-heading"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          About Construct Regulate
        </motion.h1>
        <motion.p 
          className="text-xl mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Revolutionizing the construction industry with AI-powered automation
        </motion.p>
      </div>
    </section>
  );
}

function MissionVision() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className="mission-vision py-16">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-4 font-heading">Our Mission</h2>
            <p className="mb-4">To empower construction companies with cutting-edge AI technology, streamlining processes and boosting efficiency without the need for complex data migrations or extensive training.</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h2 className="text-3xl font-bold mb-4 font-heading">Our Vision</h2>
            <p className="mb-4">A future where AI seamlessly integrates with construction workflows, allowing companies of all sizes to leverage advanced automation and focus on what they do best: building the world around us.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

function Team() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section ref={ref} className="team py-16 bg-light-gray">
      <div className="container mx-auto">
        <motion.h2 
          className="text-3xl font-bold mb-8 text-center font-heading"
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 0.5 }}
        >
          Our Team
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <TeamMember name="John Doe" position="CEO & Founder" delay={0} />
          <TeamMember name="Jane Smith" position="CTO" delay={0.2} />
          <TeamMember name="Mike Johnson" position="Head of AI Research" delay={0.4} />
        </div>
      </div>
    </section>
  );
}

function TeamMember({ name, position, delay }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md text-center"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay }}
    >
      <img src={`https://via.placeholder.com/150`} alt={name} className="w-32 h-32 rounded-full mx-auto mb-4" />
      <h3 className="text-xl font-bold mb-2 font-heading dark:text-white">{name}</h3>
      <p className="dark:text-gray-300">{position}</p>
    </motion.div>
  );
}

function History() {
  return (
    <section className="history py-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center font-heading">Our Journey</h2>
        <div className="max-w-3xl mx-auto">
          <Timeline />
        </div>
      </div>
    </section>
  );
}

function Timeline() {
  const milestones = [
    { year: 2018, event: "Construct Regulate founded" },
    { year: 2019, event: "Launch of first AI agent for project management" },
    { year: 2020, event: "Expanded services to include financial management and resource allocation" },
    { year: 2021, event: "Reached 100 client milestone" },
    { year: 2022, event: "Introduced advanced safety monitoring AI" },
    { year: 2023, event: "Launched comprehensive AI Agent Marketplace" }
  ];

  return (
    <div className="relative">
      {milestones.map((milestone, index) => (
        <div key={index} className="mb-8 flex">
          <div className="flex-shrink-0 w-24 text-right pr-4">
            <span className="font-bold">{milestone.year}</span>
          </div>
          <div className="border-l-2 border-bright-orange pl-4 pb-8">
            <p>{milestone.event}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

function CTA() {
  return (
    <section className="cta py-16 bg-deep-blue text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4 font-heading">Ready to transform your construction processes?</h2>
        <p className="mb-8">Join us in revolutionizing the construction industry with AI-powered automation.</p>
        <Link to="/contact">
          <Button>Get Started</Button>
        </Link>
      </div>
    </section>
  );
}

export default AboutUs;
