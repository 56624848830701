import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { 
  Activity,
  AlertTriangle,
  BarChart2,
  Brain,
  CheckCircle,
  Clock,
  Database,
  Settings,
  Wrench,
  Zap,
  ArrowRight,
  DollarSign,
  Calendar,
  Gauge,
  Cpu,
  ClipboardList,
  Link,
  Users
} from 'lucide-react';

const PredictiveMaintenance = () => {
  // Sample data for the maintenance prediction chart
  const [maintenanceData] = useState([
    { name: 'Jan', actual: 85, predicted: 82, threshold: 70 },
    { name: 'Feb', actual: 83, predicted: 80, threshold: 70 },
    { name: 'Mar', actual: 78, predicted: 77, threshold: 70 },
    { name: 'Apr', actual: 75, predicted: 74, threshold: 70 },
    { name: 'May', actual: 71, predicted: 72, threshold: 70 },
    { name: 'Jun', actual: null, predicted: 69, threshold: 70 },
    { name: 'Jul', actual: null, predicted: 65, threshold: 70 },
  ]);

  const features = [
    {
      icon: <Gauge className="w-12 h-12 text-blue-500" />,
      title: 'Real-Time Equipment Monitoring',
      description: 'Continuous data collection from sensors and IoT devices to assess machinery health in real-time.',
      benefits: [
        'Immediate anomaly detection',
        'Comprehensive performance tracking',
        'Enhanced safety monitoring'
      ],
      techDetails: [
        'IoT sensor integration',
        'Edge computing processing',
        'Real-time data streaming'
      ]
    },
    {
      icon: <Brain className="w-12 h-12 text-green-500" />,
      title: 'AI-Driven Predictive Analytics',
      description: 'Advanced algorithms analyze equipment data to predict potential failures before they occur.',
      benefits: [
        'Reduced unexpected downtime',
        'Optimized maintenance scheduling',
        'Extended equipment life'
      ],
      techDetails: [
        'Machine learning models',
        'Anomaly detection',
        'RUL estimation'
      ]
    },
    {
      icon: <Calendar className="w-12 h-12 text-purple-500" />,
      title: 'Maintenance Scheduling',
      description: 'Automated maintenance planning and resource allocation based on AI predictions.',
      benefits: [
        'Efficient resource utilization',
        'Reduced maintenance costs',
        'Improved compliance'
      ],
      techDetails: [
        'CMMS integration',
        'Automated work orders',
        'Resource optimization'
      ]
    },
    {
      icon: <Activity className="w-12 h-12 text-indigo-500" />,
      title: 'Root Cause Analysis',
      description: 'Identify underlying causes of equipment issues using advanced data analytics.',
      benefits: [
        'Prevent recurring problems',
        'Knowledge-based decisions',
        'Continuous improvement'
      ],
      techDetails: [
        'Data mining algorithms',
        'Pattern recognition',
        'Failure mode analysis'
      ]
    }
  ];

  const stats = [
    {
      icon: <Clock className="w-8 h-8 text-blue-500" />,
      value: "25",
      unit: "%",
      label: "Reduction in Downtime"
    },
    {
      icon: <DollarSign className="w-8 h-8 text-green-500" />,
      value: "20",
      unit: "%",
      label: "Decrease in Maintenance Costs"
    },
    {
      icon: <Wrench className="w-8 h-8 text-purple-500" />,
      value: "15",
      unit: "%",
      label: "Extended Equipment Life"
    }
  ];

  const MaintenanceChart = () => (
    <div className="bg-white p-6 rounded-xl shadow-lg">
      <h3 className="text-xl font-bold mb-4">Equipment Health Prediction</h3>
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={maintenanceData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[60, 100]} />
            <Tooltip />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="actual" 
              stroke="#2563eb" 
              strokeWidth={2}
              name="Actual Health"
              dot={{ r: 4 }}
            />
            <Line 
              type="monotone" 
              dataKey="predicted" 
              stroke="#16a34a" 
              strokeWidth={2}
              strokeDasharray="5 5"
              name="Predicted Health"
              dot={{ r: 4 }}
            />
            <Line 
              type="monotone" 
              dataKey="threshold" 
              stroke="#dc2626" 
              strokeWidth={2}
              name="Maintenance Threshold"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 p-4 bg-yellow-50 rounded-lg flex items-start gap-3">
        <AlertTriangle className="w-5 h-5 text-yellow-600 flex-shrink-0 mt-1" />
        <p className="text-sm text-yellow-700">
          Maintenance recommended in the next 30 days based on predicted equipment health trajectory.
        </p>
      </div>
    </div>
  );

  const aiCapabilities = [
    {
      icon: <Cpu className="w-12 h-12 text-rose-500" />,
      title: 'Machine Learning',
      description: 'Advanced algorithms for predictive modeling.',
      capabilities: [
        'Time series analysis',
        'Deep learning models',
        'Adaptive algorithms'
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-amber-500" />,
      title: 'IoT & Edge Computing',
      description: 'Real-time data processing at the source.',
      capabilities: [
        'Edge analytics',
        'Sensor integration',
        'Real-time monitoring'
      ]
    },
    {
      icon: <Database className="w-12 h-12 text-cyan-500" />,
      title: 'Big Data Processing',
      description: 'Handle and analyze large volumes of equipment data.',
      capabilities: [
        'Scalable storage',
        'Parallel processing',
        'Data warehousing'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Predictive Maintenance Automation
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Utilize AI to predict equipment maintenance needs, reduce unexpected downtime, and extend the life of your valuable machinery. Transform reactive maintenance into proactive optimization.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                Schedule Demo
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Learn More
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Live Monitoring Dashboard Preview */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Real-Time Equipment Monitoring
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              Monitor your equipment health in real-time and receive AI-powered predictions for maintenance needs before failures occur.
            </p>
            
            <div className="grid md:grid-cols-2 gap-8">
              <MaintenanceChart />
              <div className="grid grid-cols-1 gap-6">
                {[
                  {
                    icon: <Settings className="w-8 h-8 text-blue-500" />,
                    title: "Equipment Status",
                    status: "Operating Normally",
                    details: "Next maintenance predicted in 27 days"
                  },
                  {
                    icon: <AlertTriangle className="w-8 h-8 text-yellow-500" />,
                    title: "Active Alerts",
                    status: "1 Warning",
                    details: "Hydraulic pressure trending downward"
                  },
                  {
                    icon: <BarChart2 className="w-8 h-8 text-green-500" />,
                    title: "Performance Metrics",
                    status: "92% Efficiency",
                    details: "3% above monthly average"
                  }
                ].map((item, index) => (
                  <motion.div
                    key={item.title}
                    className="bg-white p-6 rounded-xl shadow-lg"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className="flex items-center gap-4 mb-3">
                      {item.icon}
                      <h3 className="text-lg font-semibold">{item.title}</h3>
                    </div>
                    <p className="text-2xl font-bold mb-2">{item.status}</p>
                    <p className="text-gray-600">{item.details}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
{/* AI Capabilities Section */}
<div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Advanced AI Capabilities</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Powered by cutting-edge artificial intelligence technologies that revolutionize equipment maintenance.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {aiCapabilities.map((capability, index) => (
              <motion.div
                key={capability.title}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5 }}
              >
                <div className="mb-6">{capability.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{capability.title}</h3>
                <p className="text-gray-600 mb-6">{capability.description}</p>
                <ul className="space-y-3">
                  {capability.capabilities.map((item, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* ROI Calculator */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">ROI Impact Calculator</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold text-xl mb-4">Annual Cost Savings</h3>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <span>Reduced Downtime</span>
                      <span className="font-bold text-green-600">$250,000</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Maintenance Optimization</span>
                      <span className="font-bold text-green-600">$180,000</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Extended Equipment Life</span>
                      <span className="font-bold text-green-600">$170,000</span>
                    </div>
                    <div className="h-px bg-gray-300 my-2"></div>
                    <div className="flex justify-between items-center text-lg font-bold">
                      <span>Total Annual Savings</span>
                      <span className="text-green-600">$600,000</span>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="font-semibold text-xl mb-4">Implementation Investment</h3>
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <span>Initial Setup</span>
                      <span className="font-bold text-blue-600">$300,000</span>
                    </div>
                    <div className="h-px bg-gray-300 my-2"></div>
                    <div className="flex justify-between items-center text-lg font-bold">
                      <span>First Year ROI</span>
                      <span className="text-blue-600">200%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-xl p-6 shadow-lg">
                <h3 className="font-semibold text-xl mb-4">Projected 3-Year Impact</h3>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={[
                    { year: '2024', savings: 600000 },
                    { year: '2025', savings: 750000 },
                    { year: '2026', savings: 900000 }
                  ]}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis />
                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                    <Line type="monotone" dataKey="savings" stroke="#2563eb" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
                <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                  <p className="text-sm text-blue-700">
                    Projected savings increase as AI models improve and system optimization continues.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Implementation Process */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Implementation Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our structured approach ensures a smooth transition to AI-powered predictive maintenance.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                step: "1",
                title: "Equipment Assessment",
                description: "Identify critical assets and determine appropriate monitoring solutions",
                icon: <ClipboardList className="w-8 h-8 text-blue-500" />
              },
              {
                step: "2",
                title: "Sensor Deployment",
                description: "Install IoT sensors and set up data collection infrastructure",
                icon: <Settings className="w-8 h-8 text-green-500" />
              },
              {
                step: "3",
                title: "AI Model Training",
                description: "Train predictive models using historical and real-time data",
                icon: <Brain className="w-8 h-8 text-purple-500" />
              },
              {
                step: "4",
                title: "System Integration",
                description: "Connect with existing maintenance management systems",
                icon: <Link className="w-8 h-8 text-indigo-500" />
              },
              {
                step: "5",
                title: "Team Training",
                description: "Comprehensive training for maintenance staff",
                icon: <Users className="w-8 h-8 text-rose-500" />
              },
              {
                step: "6",
                title: "Optimization",
                description: "Continuous monitoring and refinement of predictions",
                icon: <Activity className="w-8 h-8 text-amber-500" />
              }
            ].map((phase, index) => (
              <motion.div
                key={phase.step}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="w-12 h-12 bg-gradient-to-br from-blue-50 to-purple-50 rounded-full flex items-center justify-center mx-auto mb-6 shadow-md">
                  <span className="text-blue-600 font-bold text-xl">{phase.step}</span>
                </div>
                <div className="flex justify-center mb-4">{phase.icon}</div>
                <h3 className="text-xl font-bold mb-3 text-center">{phase.title}</h3>
                <p className="text-gray-600 text-center">{phase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Case Study Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">Success Story: Construction Equipment Fleet</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-xl mb-2">Challenge:</h3>
                <p className="text-gray-600">
                  A construction firm experienced frequent equipment failures leading to unplanned downtime, 
                  project delays, and increased maintenance costs.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Solution:</h3>
                <p className="text-gray-600">
                  We implemented IoT sensors and AI predictive models across their equipment fleet, 
                  integrating with their existing maintenance management system.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Results:</h3>
                <ul className="space-y-2">
                  {[
                    '25% reduction in equipment downtime',
                    '20% decrease in maintenance costs',
                    'Extended equipment lifespan by 15%',
                    '200% ROI achieved in first year'
                  ].map((result, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-600">{result}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Take Control of Your Equipment's Health</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Experience the benefits of AI-powered predictive maintenance. Reduce downtime, optimize costs, 
              and extend equipment life with our intelligent solutions.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule Your Free Consultation
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PredictiveMaintenance;
