import React from 'react';

function NewAgentPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">New Agent Name</h1>
      {/* Add content specific to this agent */}
    </div>
  );
}

export default NewAgentPage;
