import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { 
  Activity, 
  RefreshCw, 
  Settings, 
  BookOpen, 
  HeadphonesIcon, 
  CheckCircle,
  LineChart,
  Clock,
  Shield,
  Users
} from 'lucide-react';

const OngoingSupport = () => {
  const supportServices = [
    {
      icon: <Activity className="w-12 h-12 text-blue-500" />,
      title: 'Proactive System Monitoring',
      description: 'We continuously monitor your automation systems to ensure peak performance and identify potential issues before they impact operations.',
      benefits: [
        'Minimized system downtime',
        'Optimized performance tracking',
        'Real-time issue resolution'
      ]
    },
    {
      icon: <RefreshCw className="w-12 h-12 text-green-500" />,
      title: 'Regular Updates & Enhancements',
      description: 'Keep your systems current with regular updates that incorporate the latest features, security patches, and improvements.',
      benefits: [
        'Access to latest features',
        'Enhanced security measures',
        'Regulatory compliance updates'
      ]
    },
    {
      icon: <Settings className="w-12 h-12 text-purple-500" />,
      title: 'Customized Optimization',
      description: 'We work closely with you to identify and implement opportunities for further optimization of your automation systems.',
      benefits: [
        'Tailored improvement strategies',
        'Scalable solutions',
        'Performance optimization'
      ]
    },
    {
      icon: <BookOpen className="w-12 h-12 text-indigo-500" />,
      title: 'Training & Knowledge Transfer',
      description: 'Ongoing training sessions and resources to ensure your team maximizes the benefits of your automation tools.',
      benefits: [
        'Comprehensive skill development',
        'User adoption support',
        'Self-sufficiency training'
      ]
    },
    {
      icon: <HeadphonesIcon className="w-12 h-12 text-rose-500" />,
      title: '24/7 Technical Support',
      description: 'Our support team is available around the clock to assist with any technical issues or questions that arise.',
      benefits: [
        'Immediate assistance',
        'Expert technical guidance',
        'Reliable support access'
      ]
    }
  ];

  const stats = [
    {
      icon: <Clock className="w-8 h-8 text-blue-500" />,
      value: "99.9",
      unit: "%",
      label: "System Uptime"
    },
    {
      icon: <LineChart className="w-8 h-8 text-green-500" />,
      value: "30",
      unit: "%",
      label: "Efficiency Increase"
    },
    {
      icon: <Shield className="w-8 h-8 text-purple-500" />,
      value: "24/7",
      unit: "",
      label: "Support Coverage"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Ongoing Support & Optimization | Construct Regulate</title>
        <meta name="description" content="Ensure your automation systems evolve with your business needs. Our dedicated team provides continuous support and optimization for sustained success." />
        <meta name="keywords" content="ongoing support, AI optimization, construction technology, system maintenance, performance tuning" />
        <meta property="og:title" content="Ongoing Support & Optimization | Construct Regulate" />
        <meta property="og:description" content="Keep your AI-powered construction systems at peak performance. Our ongoing support ensures continuous optimization and adaptation to your evolving needs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/ongoing-support" />
        <meta property="og:image" content="https://constructregulate.com/images/ongoing-support-og-image.jpg" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Ongoing Support & Optimization
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Our dedicated team ensures your automation systems evolve with your business needs, providing continuous support and optimization for sustained success.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                View Support Plans
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Contact Support
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Comprehensive Support for Your Success
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              Implementation is just the beginning. Our ongoing support ensures your automation systems continue to deliver maximum value as your business grows and evolves.
            </p>
            
            {/* Stats Section */}
            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex justify-center mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-blue-900 mb-2">
                    {stat.value}<span className="text-blue-500">{stat.unit}</span>
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Support Services Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Our Support Services</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Comprehensive support services designed to keep your automation systems running at peak performance.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {supportServices.map((service, index) => (
              <motion.div
                key={service.title}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="mb-6">{service.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-3">
                  {service.benefits.map((benefit, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-700">{benefit}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Case Study Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">Success Story: Growing Construction Firm</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-xl mb-2">Challenge:</h3>
                <p className="text-gray-600">
                  A construction company needed to scale their automation systems to match their rapid growth while maintaining efficiency.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Solution:</h3>
                <p className="text-gray-600">
                  Our continuous support services provided system scaling, regular updates, and comprehensive training for new staff.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Results:</h3>
                <ul className="space-y-2">
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">30% increase in overall efficiency</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">Seamless scaling of operations</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">Improved employee satisfaction and retention</span>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Ready to Ensure Long-Term Success?</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Partner with us to keep your automation systems performing at their best. Our dedicated support team is here to help you succeed.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule a Consultation
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OngoingSupport;
