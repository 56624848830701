import React from 'react';
import '../styles/ParticleBackground.css';

const ParticleBackground = ({ isDarkMode }) => {
  return (
    <div className={`particle-background ${isDarkMode ? 'dark' : 'light'} fixed top-0 left-0 w-full h-full pointer-events-none`}>
      {[...Array(50)].map((_, i) => (
        <div key={i} className="particle" />
      ))}
    </div>
  );
};

export default ParticleBackground;
