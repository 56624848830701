import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  AlertTriangle,
  HardHat,
  Shield,
  Activity,
  Users,
  Thermometer,
  Wind,
  Volume2,
  Bell,
  Check,
  X,
  AlertCircle,
  Clock,
  Camera,
  UserCheck,
  BarChart2
} from 'lucide-react';

const SafetyMonitoringDashboard = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [alertCount, setAlertCount] = useState(0);
  const [safetyScore, setSafetyScore] = useState(85);
  
  // Simulate real-time updates
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      // Simulate fluctuating safety metrics
      setSafetyScore(prev => Math.max(80, Math.min(100, prev + (Math.random() - 0.5) * 2)));
      // Randomly add alerts
      if (Math.random() < 0.1) {
        setAlertCount(prev => prev + 1);
      }
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  // Live monitoring data
  const [sensorData] = useState({
    temperature: 24.5,
    noise: 82,
    airQuality: 92,
    humidity: 45,
    workers: 28,
    activeZones: 5
  });

  // Active alerts
  const liveAlerts = [
    {
      id: 1,
      type: 'warning',
      message: 'Worker detected in restricted area - Zone B',
      timestamp: '2 mins ago',
      status: 'active'
    },
    {
      id: 2,
      type: 'critical',
      message: 'PPE compliance violation - Hard hat missing',
      timestamp: 'Just now',
      status: 'new'
    },
    {
      id: 3,
      type: 'info',
      message: 'Equipment maintenance due in 24 hours',
      timestamp: '5 mins ago',
      status: 'pending'
    }
  ];

  const safetyTrends = [
    { time: '08:00', compliance: 88, incidents: 0, nearMisses: 2 },
    { time: '09:00', compliance: 92, incidents: 0, nearMisses: 1 },
    { time: '10:00', compliance: 90, incidents: 1, nearMisses: 3 },
    { time: '11:00', compliance: 95, incidents: 0, nearMisses: 1 },
    { time: '12:00', compliance: 93, incidents: 0, nearMisses: 2 },
    { time: '13:00', compliance: 91, incidents: 0, nearMisses: 1 }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Top Bar */}
      <div className="bg-gray-800 p-4 border-b border-gray-700">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center gap-4">
            <Shield className="w-8 h-8 text-blue-400" />
            <h1 className="text-2xl font-bold">Safety Monitoring Center</h1>
          </div>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <Clock className="w-5 h-5 text-gray-400" />
              <span>{currentTime.toLocaleTimeString()}</span>
            </div>
            <div className="flex items-center gap-2">
              <UserCheck className="w-5 h-5 text-green-400" />
              <span>{sensorData.workers} Workers On-Site</span>
            </div>
            <div className="flex items-center gap-2">
              <Bell className="w-5 h-5 text-yellow-400" />
              <span>{alertCount} New Alerts</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-6">
        <div className="grid grid-cols-12 gap-6">
          {/* Safety Score Card */}
          <div className="col-span-3 bg-gray-800 rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Safety Score</h2>
              <Shield className="w-6 h-6 text-blue-400" />
            </div>
            <div className="text-4xl font-bold mb-2 text-blue-400">
              {safetyScore.toFixed(1)}%
            </div>
            <div className="h-2 bg-gray-700 rounded-full">
              <div 
                className="h-full bg-blue-400 rounded-full transition-all duration-500"
                style={{ width: `${safetyScore}%` }}
              />
            </div>
            <p className="text-sm text-gray-400 mt-2">
              {safetyScore > 90 ? 'Excellent' : safetyScore > 80 ? 'Good' : 'Needs Attention'}
            </p>
          </div>

          {/* Environmental Monitoring */}
          <div className="col-span-9 grid grid-cols-4 gap-4">
            {[
              { icon: <Thermometer className="w-6 h-6" />, label: 'Temperature', value: `${sensorData.temperature}°C`, color: 'text-red-400' },
              { icon: <Volume2 className="w-6 h-6" />, label: 'Noise Level', value: `${sensorData.noise} dB`, color: 'text-yellow-400' },
              { icon: <Wind className="w-6 h-6" />, label: 'Air Quality', value: `${sensorData.airQuality}%`, color: 'text-green-400' },
              { icon: <Users className="w-6 h-6" />, label: 'Active Zones', value: sensorData.activeZones, color: 'text-purple-400' }
            ].map((metric, index) => (
              <div key={index} className="bg-gray-800 rounded-lg p-4">
                <div className="flex items-center gap-3 mb-2">
                  <span className={metric.color}>{metric.icon}</span>
                  <span className="text-sm text-gray-400">{metric.label}</span>
                </div>
                <div className="text-2xl font-bold">{metric.value}</div>
              </div>
            ))}
          </div>

          {/* Live Alerts */}
          <div className="col-span-4 bg-gray-800 rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Live Alerts</h2>
              <AlertTriangle className="w-6 h-6 text-yellow-400" />
            </div>
            <div className="space-y-4">
              {liveAlerts.map(alert => (
                <div 
                  key={alert.id}
                  className={`p-4 rounded-lg ${
                    alert.type === 'critical' ? 'bg-red-900/30' :
                    alert.type === 'warning' ? 'bg-yellow-900/30' :
                    'bg-blue-900/30'
                  }`}
                >
                  <div className="flex items-start gap-3">
                    <AlertCircle className={`w-5 h-5 flex-shrink-0 ${
                      alert.type === 'critical' ? 'text-red-400' :
                      alert.type === 'warning' ? 'text-yellow-400' :
                      'text-blue-400'
                    }`} />
                    <div>
                      <p className="text-sm">{alert.message}</p>
                      <span className="text-xs text-gray-400">{alert.timestamp}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Safety Trends */}
          <div className="col-span-8 bg-gray-800 rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Safety Trends</h2>
              <BarChart2 className="w-6 h-6 text-blue-400" />
            </div>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={safetyTrends}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="time" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: 'none',
                      borderRadius: '0.5rem',
                      color: '#fff'
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="compliance"
                    stroke="#3B82F6"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="nearMisses"
                    stroke="#EF4444"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Camera Feeds */}
          <div className="col-span-12 grid grid-cols-4 gap-4">
            {[1, 2, 3, 4].map((camera) => (
              <div key={camera} className="bg-gray-800 rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold">Camera {camera}</span>
                  <Camera className="w-5 h-5 text-blue-400" />
                </div>
                <div className="aspect-video bg-gray-900 rounded relative">
                  <img 
                    src={`/api/placeholder/400/225`} 
                    alt={`Camera feed ${camera}`}
                    className="w-full h-full object-cover rounded"
                  />
                  <div className="absolute bottom-2 left-2 bg-black/50 px-2 py-1 rounded text-xs">
                    Zone {String.fromCharCode(64 + camera)}
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-400">
                  Live Feed - Monitoring Active
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyMonitoringDashboard;