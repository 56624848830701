import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell
} from 'recharts';
import {
  DollarSign, TrendingUp, CreditCard, ArrowUpRight, 
  ArrowDownRight, Clock, Activity, Wallet, BarChart2,
  FileText, Users, Bell, Mail, AlertTriangle, HardHat,
  Wrench, Hammer, RefreshCw, X
} from 'lucide-react';

const FinancialDashboard = () => {
  // All state variables
  const [cashFlow, setCashFlow] = useState(2450000);
  const [pendingInvoices, setPendingInvoices] = useState(15);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [laborCost, setLaborCost] = useState(125147);
  const [carpenterDues, setCarpenterDues] = useState(48957);
  const [laborDues, setLaborDues] = useState(52748);
  const [cementDues, setCementDues] = useState(68475);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  // Simulate real-time updates
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      fetchLatestData();
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (refreshKey > 0) {
      fetchLatestData();
    }
  }, [refreshKey]);

  const fetchLatestData = () => {
    setCashFlow(prev => prev + (Math.random() - 0.5) * 10000);
    setPendingInvoices(prev => Math.max(0, prev + (Math.random() > 0.7 ? 1 : -1)));
    setLaborCost(prev => Math.min(195847, Math.max(125147, prev + (Math.random() - 0.5) * 1000)));
    setCarpenterDues(prev => Math.min(74987, Math.max(48957, prev + (Math.random() - 0.5) * 500)));
    setLaborDues(prev => Math.min(72748, Math.max(52748, prev + (Math.random() - 0.5) * 500)));
    setCementDues(prev => Math.min(87102, Math.max(68475, prev + (Math.random() - 0.5) * 500)));
  };

  const generatePaymentHistory = (months = 12) => {
    const history = [];
    const today = new Date();
    
    for (let i = 0; i < months; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      history.unshift({
        date: date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
        amount: Math.floor(Math.random() * 100000) + 50000,
        status: Math.random() > 0.2 ? 'Paid' : 'Late',
        daysToPayment: Math.floor(Math.random() * 45) + 1
      });
    }
    return history;
  };

  // Revenue Trends Data
  const revenueTrends = [
    { month: 'Jan', actual: 1200000, predicted: 1150000 },
    { month: 'Feb', actual: 1350000, predicted: 1300000 },
    { month: 'Mar', actual: 1450000, predicted: 1400000 },
    { month: 'Apr', actual: 1600000, predicted: 1550000 },
    { month: 'May', actual: 1750000, predicted: 1700000 },
    { month: 'Jun', actual: null, predicted: 1850000 },
    { month: 'Jul', actual: null, predicted: 2000000 }
  ];

  // Cost Breakdown Data
  const costBreakdown = [
    { name: 'Labor', value: 45, color: '#3B82F6' },
    { name: 'Materials', value: 30, color: '#10B981' },
    { name: 'Equipment', value: 15, color: '#6366F1' },
    { name: 'Overhead', value: 10, color: '#F59E0B' }
  ];

  // Accounts Receivable Data
  const accountsReceivable = [
    {
      client: "ABC Construction",
      current: 125000,
      days30: 75000,
      days60: 45000,
      days90: 15000,
      status: "warning"
    },
    {
      client: "XYZ Developers",
      current: 85000,
      days30: 0,
      days60: 35000,
      days90: 95000,
      status: "critical"
    },
    {
      client: "Metro Buildings",
      current: 165000,
      days30: 25000,
      days60: 0,
      days90: 0,
      status: "good"
    }
  ];

  // Accounts Payable Data
  const accountsPayable = [
    {
      vendor: "Steel Supply Co",
      current: 45000,
      days30: 35000,
      days60: 25000,
      days90: 85000,
      status: "critical",
      needsReminder: true
    },
    {
      vendor: "Concrete Masters",
      current: 75000,
      days30: 45000,
      days60: 0,
      days90: 0,
      status: "good",
      needsReminder: false
    },
    {
      vendor: "Equipment Rental Inc",
      current: 25000,
      days30: 65000,
      days60: 15000,
      days90: 0,
      status: "warning",
      needsReminder: true
    }
  ];

  // Project Budget Status
  const projectBudgets = [
    {
      name: 'Project A',
      budget: 500000,
      spent: 350000,
      remaining: 150000
    },
    {
      name: 'Project B',
      budget: 750000,
      spent: 400000,
      remaining: 350000
    },
    {
      name: 'Project C',
      budget: 300000,
      spent: 280000,
      remaining: 20000
    }
  ];

  // Component for Quick Stats Cards
  const QuickStatCard = ({ icon: Icon, label, value, trend, trendValue, color }) => (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <div className="flex justify-between items-start mb-4">
        <div className="p-2 rounded-lg bg-opacity-10" style={{ backgroundColor: `${color}20` }}>
          <Icon className={`w-6 h-6 ${color}`} />
        </div>
        {trend && (
          <div className={`flex items-center ${trend === 'up' ? 'text-green-500' : 'text-red-500'}`}>
            {trend === 'up' ? <ArrowUpRight className="w-4 h-4" /> : <ArrowDownRight className="w-4 h-4" />}
            <span className="text-sm font-semibold">{trendValue}%</span>
          </div>
        )}
      </div>
      <h3 className="text-gray-500 text-sm mb-1">{label}</h3>
      <div className="text-2xl font-bold text-gray-900">{value}</div>
    </div>
  );

  // Component for Union Dues Cards
  const UnionDuesCard = ({ icon: Icon, title, amount, maxAmount }) => (
    <div className="bg-white rounded-xl p-6 shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <Icon className="w-6 h-6 text-blue-500" />
          <h3 className="font-semibold text-gray-700">{title}</h3>
        </div>
        <div className="text-sm text-gray-500">MTD</div>
      </div>
      <div className="text-2xl font-bold text-gray-900">
        ${amount.toLocaleString()}
      </div>
      <div className="mt-2 h-2 bg-gray-100 rounded-full">
        <div 
          className="h-full bg-blue-500 rounded-full transition-all duration-500"
          style={{ width: `${(amount / maxAmount) * 100}%` }}
        />
      </div>
    </div>
  );

  // Status Indicator Component
  const StatusIndicator = ({ status }) => {
    const colors = {
      good: "bg-green-500",
      warning: "bg-yellow-500",
      critical: "bg-red-500"
    };
    return <div className={`w-3 h-3 rounded-full ${colors[status]}`} />;
  };

  // Enhanced DetailsModal component
  const DetailsModal = ({ data, onClose, type }) => {
    const paymentHistory = generatePaymentHistory();
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-6 max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h3 className="text-2xl font-bold text-gray-900">
                {type === 'vendor' ? data.vendor : data.client}
              </h3>
              <p className="text-sm text-gray-500 mt-1">
                Account Details & Payment History
              </p>
            </div>
            <button 
              onClick={onClose} 
              className="text-gray-500 hover:text-gray-700 p-2 rounded-lg hover:bg-gray-100"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          
          {/* Summary Cards */}
          <div className="grid grid-cols-4 gap-4 mb-6">
            {[
              {
                label: 'Total Outstanding',
                value: `$${(data.current + data.days30 + data.days60 + data.days90).toLocaleString()}`,
                icon: DollarSign,
                color: 'text-blue-500'
              },
              {
                label: 'Average Days to Pay',
                value: '32 Days',
                icon: Clock,
                color: 'text-green-500'
              },
              {
                label: 'Payment Status',
                value: data.status.charAt(0).toUpperCase() + data.status.slice(1),
                icon: AlertTriangle,
                color: data.status === 'good' ? 'text-green-500' : 
                       data.status === 'warning' ? 'text-yellow-500' : 'text-red-500'
              },
              {
                label: 'Credit Limit',
                value: '$250,000',
                icon: CreditCard,
                color: 'text-purple-500'
              }
            ].map((item, index) => (
              <div key={index} className="bg-gray-50 rounded-xl p-4">
                <div className="flex items-center gap-2 mb-2">
                  <item.icon className={`w-5 h-5 ${item.color}`} />
                  <span className="text-sm text-gray-600">{item.label}</span>
                </div>
                <div className="text-xl font-bold text-gray-900">{item.value}</div>
              </div>
            ))}
          </div>

          {/* Payment History Table */}
          <div className="mb-6">
            <h4 className="font-semibold text-lg mb-4">Payment History</h4>
            <div className="bg-white rounded-xl overflow-hidden border border-gray-200">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Date</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold text-gray-600">Amount</th>
                    <th className="px-4 py-3 text-center text-sm font-semibold text-gray-600">Status</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold text-gray-600">Days to Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistory.map((payment, index) => (
                    <tr key={index} className="border-t border-gray-200">
                      <td className="px-4 py-3 text-sm text-gray-900">{payment.date}</td>
                      <td className="px-4 py-3 text-sm text-gray-900 text-right">
                        ${payment.amount.toLocaleString()}
                      </td>
                      <td className="px-4 py-3 text-sm text-center">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                          ${payment.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                          {payment.status}
                        </span>
                      </td>
                      <td className="px-4 py-3 text-sm text-gray-900 text-right">
                        {payment.daysToPayment} days
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Payment Trend Chart */}
          <div className="mb-6">
            <h4 className="font-semibold text-lg mb-4">Payment Trends</h4>
            <div className="bg-white rounded-xl p-4 border border-gray-200">
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={paymentHistory}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                    <XAxis 
                      dataKey="date" 
                      stroke="#6B7280"
                    />
                    <YAxis 
                      stroke="#6B7280"
                      tickFormatter={value => `$${(value / 1000).toFixed(0)}k`}
                    />
                    <Tooltip 
                      contentStyle={{
                        backgroundColor: '#FFF',
                        border: '1px solid #E5E7EB',
                        borderRadius: '0.5rem'
                      }}
                      formatter={value => `$${value.toLocaleString()}`}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="amount" 
                      stroke="#3B82F6" 
                      strokeWidth={2}
                      dot={{ r: 4 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <div className="bg-gray-50 rounded-xl p-4">
              <h4 className="font-semibold text-lg mb-4">Contact Information</h4>
              <div className="space-y-3">
                <div>
                  <p className="text-sm text-gray-500">Primary Contact</p>
                  <p className="text-gray-900">John Smith</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Email</p>
                  <p className="text-gray-900">john.smith@example.com</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Phone</p>
                  <p className="text-gray-900">(555) 123-4567</p>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 rounded-xl p-4">
              <h4 className="font-semibold text-lg mb-4">Account Details</h4>
              <div className="space-y-3">
                <div>
                  <p className="text-sm text-gray-500">Account Number</p>
                  <p className="text-gray-900">#ACC-{Math.random().toString(36).substr(2, 9).toUpperCase()}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Payment Terms</p>
                  <p className="text-gray-900">Net 30</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Account Status</p>
                  <p className="text-gray-900">Active</p>
                </div>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
            >
              Close
            </button>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center gap-2"
            >
              <Mail className="w-4 h-4" />
              Send Statement
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Top Bar */}
      <div className="bg-white border-b">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <DollarSign className="w-8 h-8 text-blue-600" />
              <h1 className="text-2xl font-bold text-gray-900">Financial Control Center</h1>
            </div>
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-2 text-gray-600">
                <Clock className="w-5 h-5" />
                <span>{currentTime.toLocaleTimeString()}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <FileText className="w-5 h-5" />
                <span>{pendingInvoices} Pending Invoices</span>
              </div>
              <div className="relative">
                <Bell className="w-5 h-5 text-gray-600 cursor-pointer" />
                <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
                  3
                </span>
              </div>
              <button 
                className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                onClick={() => setRefreshKey(prev => prev + 1)}
              >
                <RefreshCw className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 py-8">
        <div className="grid grid-cols-12 gap-6">
          {/* Quick Stats Row */}
          <div className="col-span-12 grid grid-cols-4 gap-6">
            <QuickStatCard
              icon={Wallet}
              label="Current Cash Flow"
              value={`$${cashFlow.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
              trend="up"
              trendValue="8.2"
              color="text-blue-500"
            />
            <QuickStatCard
              icon={CreditCard}
              label="Outstanding Invoices"
              value={`$${(1250000).toLocaleString()}`}
              trend="down"
              trendValue="3.1"
              color="text-green-500"
            />
            <QuickStatCard
              icon={Users}
              label="Labor Costs"
              value={`$${(890000).toLocaleString()}`}
              trend="up"
              trendValue="5.4"
              color="text-purple-500"
            />
            <QuickStatCard
              icon={Activity}
              label="Monthly Revenue"
              value={`$${(2100000).toLocaleString()}`}
              trend="up"
              trendValue="12.5"
              color="text-indigo-500"
            />
          </div>

          {/* Labor Cost Card */}
          <div className="col-span-12 bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-3">
                <HardHat className="w-6 h-6 text-blue-500" />
                <h2 className="text-xl font-bold text-gray-900">Labor Cost (Week to Date)</h2>
              </div>
              <div className="text-sm text-gray-500">Live Updates</div>
            </div>
            <div className="text-3xl font-bold text-gray-900">
              ${laborCost.toLocaleString()}
            </div>
            <div className="mt-4 h-3 bg-gray-100 rounded-full">
              <div 
                className="h-full bg-blue-500 rounded-full transition-all duration-500"
                style={{ width: `${((laborCost - 125147) / (195847 - 125147)) * 100}%` }}
              />
            </div>
          </div>

          {/* Union Dues Section */}
          <div className="col-span-12 grid grid-cols-3 gap-6">
            <UnionDuesCard
              icon={Wrench}
              title="Carpenter Union Dues"
              amount={carpenterDues}
              maxAmount={74987}
            />
            <UnionDuesCard
              icon={HardHat}
              title="Labor Union Dues"
              amount={laborDues}
              maxAmount={72748}
            />
            <UnionDuesCard
              icon={Hammer}
              title="Cement Mason Union Dues"
              amount={cementDues}
              maxAmount={87102}
            />
          </div>

          {/* AR/AP Sections */}
          <div className="col-span-6 bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">Accounts Receivable Aging</h2>
              <AlertTriangle className="w-6 h-6 text-yellow-500" />
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-sm text-gray-500 border-b">
                    <th className="pb-3 text-left">Client</th>
                    <th className="pb-3 text-right">Current</th>
                    <th className="pb-3 text-right">30 Days</th>
                    <th className="pb-3 text-right">60 Days</th>
                    <th className="pb-3 text-right">90+ Days</th>
                    <th className="pb-3 text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {accountsReceivable.map((item, index) => (
                    <tr 
                      key={index} 
                      className="border-b last:border-0 hover:bg-gray-50 cursor-pointer transition-colors"
                      onClick={() => {
                        setSelectedClient(item);
                        setShowDetailsModal(true);
                      }}
                    >
                      <td className="py-4">{item.client}</td>
                      <td className="py-4 text-right">${item.current.toLocaleString()}</td>
                      <td className="py-4 text-right">${item.days30.toLocaleString()}</td>
                      <td className="py-4 text-right">${item.days60.toLocaleString()}</td>
                      <td className="py-4 text-right">${item.days90.toLocaleString()}</td>
                      <td className="py-4">
                        <div className="flex justify-center">
                          <StatusIndicator status={item.status} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-span-6 bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">Accounts Payable Aging</h2>
              <AlertTriangle className="w-6 h-6 text-red-500" />
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-sm text-gray-500 border-b">
                    <th className="pb-3 text-left">Vendor</th>
                    <th className="pb-3 text-right">Current</th>
                    <th className="pb-3 text-right">30 Days</th>
                    <th className="pb-3 text-right">60 Days</th>
                    <th className="pb-3 text-right">90+ Days</th>
                    <th className="pb-3 text-center">Status</th>
                    <th className="pb-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {accountsPayable.map((item, index) => (
                    <tr 
                      key={index} 
                      className="border-b last:border-0 hover:bg-gray-50 cursor-pointer transition-colors"
                      onClick={() => {
                        setSelectedVendor(item);
                        setShowDetailsModal(true);
                      }}
                    >
                      <td className="py-4">{item.vendor}</td>
                      <td className="py-4 text-right">${item.current.toLocaleString()}</td>
                      <td className="py-4 text-right">${item.days30.toLocaleString()}</td>
                      <td className="py-4 text-right">${item.days60.toLocaleString()}</td>
                      <td className="py-4 text-right">${item.days90.toLocaleString()}</td>
                      <td className="py-4">
                        <div className="flex justify-center">
                          <StatusIndicator status={item.status} />
                        </div>
                      </td>
                      <td className="py-4">
                        <div className="flex justify-center">
                          {item.needsReminder && (
                            <button 
                              className="text-blue-500 hover:text-blue-700 transition-colors"
                              title="Send payment reminder"
                            >
                              <Mail className="w-5 h-5" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Revenue Trends Chart */}
          <div className="col-span-8 bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">Revenue Trends</h2>
              <TrendingUp className="w-6 h-6 text-blue-500" />
            </div>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={revenueTrends}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis dataKey="month" stroke="#6B7280" />
                  <YAxis 
                    stroke="#6B7280"
                    tickFormatter={value => `$${(value / 1000000).toFixed(1)}M`}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#FFF',
                      border: '1px solid #E5E7EB',
                      borderRadius: '0.5rem'
                    }}
                    formatter={value => `$${value.toLocaleString()}`}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="actual"
                    stroke="#3B82F6"
                    strokeWidth={2}
                    name="Actual Revenue"
                    dot={{ r: 4 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="predicted"
                    stroke="#10B981"
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    name="Predicted Revenue"
                    dot={{ r: 4 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Cost Breakdown Pie Chart */}
          <div className="col-span-4 bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">Cost Breakdown</h2>
              <PieChart className="w-6 h-6 text-blue-500" />
            </div>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={costBreakdown}
                    innerRadius={80}
                    outerRadius={140}
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {costBreakdown.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#FFF',
                      border: '1px solid #E5E7EB',
                      borderRadius: '0.5rem'
                    }}
                    formatter={value => `${value}%`}
                  />
                  <Legend
                    verticalAlign="bottom"
                    height={36}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Project Budget Status */}
          <div className="col-span-12 bg-white rounded-xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-gray-900">Project Budget Status</h2>
              <BarChart2 className="w-6 h-6 text-blue-500" />
            </div>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={projectBudgets}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis dataKey="name" stroke="#6B7280" />
                  <YAxis 
                    stroke="#6B7280"
                    tickFormatter={value => `$${(value / 1000).toFixed(0)}K`}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#FFF',
                      border: '1px solid #E5E7EB',
                      borderRadius: '0.5rem'
                    }}
                    formatter={value => `$${value.toLocaleString()}`}
                  />
                  <Legend />
                  <Bar dataKey="spent" stackId="a" fill="#3B82F6" name="Spent" />
                  <Bar dataKey="remaining" stackId="a" fill="#10B981" name="Remaining" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Details Modal */}
      {showDetailsModal && (selectedVendor || selectedClient) && (
        <DetailsModal
          data={selectedVendor || selectedClient}
          onClose={() => {
            setShowDetailsModal(false);
            setSelectedVendor(null);
            setSelectedClient(null);
          }}
          type={selectedVendor ? 'vendor' : 'client'}
        />
      )}
    </div>
  );
};

export default FinancialDashboard;
