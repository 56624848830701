import React, { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

function Button({ children, onClick, className = '' }) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded transition duration-300 ${
        isDarkMode 
          ? 'bg-secondary text-textDark hover:bg-accent' 
          : 'bg-primary text-textLight hover:bg-accent'
      } ${className}`}
    >
      {children}
    </button>
  );
}

export default Button;
