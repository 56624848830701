import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Bar } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaLightbulb, FaProjectDiagram, FaRegChartBar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// New ProcessVisualization component
const ProcessSteps = [
    {
      step: 1,
      title: 'Identify',
      subtitle: 'Discovering Automation Opportunities',
      description:
        'We immerse ourselves in your operations to understand your workflows, challenges, and goals. Through workshops, interviews, and on-site observations, we identify bottlenecks and manual tasks for automation.',
      examples: [
        'Manual Timekeeping: Field workers manually fill out timesheets, leading to inaccuracies.',
        'Document Handling: Project managers spend hours sorting permits and reports.',
        'Equipment Tracking: Lack of real-time tracking results in underutilized machinery.',
      ],
      outcome:
        'By identifying these areas, we set the stage for targeted automation that addresses your most pressing challenges.',
    },
    {
      step: 2,
      title: 'Analyze',
      subtitle: 'Assessing Automation Potential and ROI',
      description:
        'We conduct a detailed analysis of identified areas to assess feasibility and estimate ROI. This involves mapping workflows, evaluating technology, and prioritizing opportunities based on impact and ease of implementation.',
      examples: [
        'Workflow Mapping: Identify communication gaps between suppliers and site managers.',
        'ROI Calculation: Estimate savings by automating safety compliance checks.',
        'Feasibility Study: Evaluate AI-powered drones for site inspections.',
      ],
      outcome:
        'We deliver a roadmap that prioritizes automation projects based on potential benefits and business goals.',
    },
    {
      step: 3,
      title: 'Automate',
      subtitle: 'Implementing Tailored Automation Solutions',
      description:
        'With a strategic plan in place, we develop and deploy customized automation solutions. These solutions integrate seamlessly with your existing systems to ensure minimal disruption.',
      examples: [
        'Automated Timekeeping: Mobile apps sync data to payroll automatically.',
        'Digital Document Management: AI-powered tools retrieve documents instantly.',
        'Real-Time Equipment Monitoring: IoT sensors track usage and maintenance.',
      ],
      outcome:
        'Our automation solutions streamline operations, boost productivity, and reduce human error.',
    },
    {
      step: 4,
      title: 'Optimize',
      subtitle: 'Continuous Improvement for Peak Performance',
      description:
        'We provide ongoing support to monitor and optimize your systems. Our solutions evolve with your needs, ensuring they continue to deliver maximum value.',
      examples: [
        'Performance Monitoring: Ensure project timelines are met efficiently.',
        'Feedback Integration: Refine user interfaces based on employee input.',
        'Advanced Analytics: AI models predict project risks to avoid delays.',
      ],
      outcome:
        'By continuously optimizing automation, we help you maintain operational excellence and stay ahead of the competition.',
    },
  ];

const ProcessVisualization = () => {
  return (
    <div className="container mx-auto px-6 py-16">
      <h2 className="text-4xl font-bold text-center mb-8">Our Process in 4 Steps</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {ProcessSteps.map((step, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center text-center p-6 shadow-lg rounded-lg bg-white"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.2 }}
          >
            <div className="w-16 h-16 bg-teal-600 text-white rounded-full flex items-center justify-center mb-4">
              {step.step}
            </div>
            <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
            <h4 className="text-teal-500 font-medium mb-4">{step.subtitle}</h4>
            <p className="text-gray-700 mb-4">{step.description}</p>
            <ul className="list-disc list-inside text-left space-y-2 mb-4">
              {step.examples.map((example, idx) => (
                <li key={idx} className="text-gray-700">{example}</li>
              ))}
            </ul>
            <p className="text-gray-800 font-medium">{step.outcome}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

// New CaseStudies component
const CaseStudies = () => (
  <div className="container mx-auto px-6 py-16 bg-gray-100">
    <h2 className="text-4xl font-bold text-center mb-8">Examples of Success</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
      <div className="p-8 shadow-lg rounded-lg bg-white">
        <h3 className="text-2xl font-semibold mb-4">Case Study 1: Streamlining Safety Compliance</h3>
        <p className="text-gray-700 mb-4">
          <strong>Challenge:</strong> A mid-sized construction firm struggled to keep up with safety inspections.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Solution:</strong> We implemented an AI-powered system that automated safety checklists and generated reports.
        </p>
        <p className="text-gray-700">
          <strong>Result:</strong> The firm saw a 75% reduction in administrative time and fewer safety incidents.
        </p>
      </div>
      <div className="p-8 shadow-lg rounded-lg bg-white">
        <h3 className="text-2xl font-semibold mb-4">Case Study 2: Optimizing Supply Chain Management</h3>
        <p className="text-gray-700 mb-4">
          <strong>Challenge:</strong> Delays due to material shortages were impacting project timelines.
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Solution:</strong> An AI agent was deployed to predict material needs and automate orders.
        </p>
        <p className="text-gray-700">
          <strong>Result:</strong> Project completion rates improved by 40%, and inventory costs decreased by 15%.
        </p>
      </div>
    </div>
  </div>
);

const ProcessDiscoveryPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const chartData = {
    labels: ['Workshops', 'Interviews', 'Assessments', 'Automation'],
    datasets: [
      {
        label: 'Process Efficiency Gains (%)',
        data: [40, 60, 70, 85],
        backgroundColor: ['#4ADE80', '#60A5FA', '#A78BFA', '#F472B6'],
        borderRadius: 8,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  const consultationRef = useRef(null);

  const handleConsultationClick = () => {
    if (consultationRef.current) {
      consultationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <Helmet>
        <title>Process Discovery & Assessment | Construct Regulate</title>
        <meta name="description" content="Uncover high-impact automation opportunities tailored to your construction business. Our experts collaborate with you to identify key areas for AI-driven improvements." />
        <meta name="keywords" content="process discovery, automation assessment, construction efficiency, AI optimization, workflow analysis" />
        <meta property="og:title" content="Process Discovery & Assessment | Construct Regulate" />
        <meta property="og:description" content="Transform your construction processes with our expert Process Discovery & Assessment service. Identify automation opportunities and boost efficiency." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://constructregulate.com/process-discovery" />
        <meta property="og:image" content="https://constructregulate.com/images/process-discovery-og-image.jpg" />
      </Helmet>

      {/* Hero Section */}
      <div className="relative h-[70vh] bg-gradient-to-r from-cyan-500 to-blue-700 overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <motion.div
          className="absolute inset-0 flex flex-col items-center justify-center text-white text-center px-8 space-y-6"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-extrabold">
            Process Discovery & Assessment
          </h1>
          <p className="text-lg max-w-2xl">
            Collaborate with our experts to uncover high-impact automation
            opportunities tailored to your business.
          </p>
          <button className="bg-teal-400 text-white px-8 py-3 rounded-full text-xl font-medium hover:bg-teal-500 transition-all duration-300">
            Schedule a Free Assessment
          </button>
        </motion.div>
      </div>

      {/* What We Do Section */}
      <div className="container mx-auto px-6 py-16">
        <div data-aos="fade-up">
          <h2 className="text-4xl font-bold text-center mb-8">What We Do</h2>
          <p className="text-center text-lg mb-12 max-w-3xl mx-auto">
            Our experts collaborate closely with your team to identify key areas
            where automation can provide measurable benefits. We ensure that
            each recommendation delivers the highest possible impact with
            minimal disruption to your workflow.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {[
            {
              icon: <FaLightbulb className="text-teal-400 text-5xl mb-4" />,
              title: 'Collaborative Analysis',
              description:
                'Workshops, interviews, and assessments ensure we capture every important detail.',
            },
            {
              icon: <FaProjectDiagram className="text-blue-400 text-5xl mb-4" />,
              title: 'Impact & ROI Prioritization',
              description:
                'We prioritize automation opportunities by their potential return on investment and operational impact.',
            },
            {
              icon: <FaRegChartBar className="text-purple-400 text-5xl mb-4" />,
              title: 'Seamless Integration',
              description:
                'Our solutions fit seamlessly into your existing workflow, ensuring smooth and efficient adoption.',
            },
          ].map((service, index) => (
            <motion.div
              className="p-8 shadow-lg rounded-lg bg-white"
              key={index}
              whileHover={{ scale: 1.05 }}
            >
              {service.icon}
              <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-700">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Chart Section */}
      <div className="bg-gray-100 py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-8">
            Automation Efficiency Over Time
          </h2>
          <div className="h-96">
            <Bar data={chartData} options={chartOptions} />
          </div>
          <p className="text-center text-lg mt-8 max-w-2xl mx-auto">
            As automation becomes embedded in your operations, efficiency gains
            grow, leading to faster project completion, fewer errors, and lower
            costs.
          </p>
        </div>
      </div>

      {/* Updated Process Workflow Visualization Section */}
      <ProcessVisualization />

      {/* New Case Studies Section */}
      <CaseStudies />

      {/* Call to Action Section */}
      <div ref={consultationRef} className="bg-gradient-to-r from-blue-700 to-cyan-500 text-white py-16">
        <div className="container mx-auto text-center space-y-6">
          <h2 className="text-5xl font-bold">
            Let’s Discover What’s Possible
          </h2>
          <p className="text-lg">
            Unlock the full potential of your business with our Process
            Discovery & Assessment service.
          </p>
          <Link to="/contact">
            <button
              className="bg-white text-teal-700 px-8 py-3 rounded-full text-xl font-medium hover:bg-gray-200 transition-all duration-300"
            >
              Schedule a Free Assessment
            </button>
          </Link>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto text-center">
        </div>
      </footer>
    </div>
  );
};

export default ProcessDiscoveryPage;
