import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { 
  Users,
  Truck, 
  Map,
  LineChart,
  Brain,
  CheckCircle,
  ArrowRight,
  Clock,
  Target,
  Settings,
  Cpu,
  Radio,
  Building,
  BarChart2,
  Wrench,
  Shield,
  AlertCircle,
  Lock,
  ClipboardList
} from 'lucide-react';

const ResourceAllocation = () => {
  const features = [
    {
      icon: <Users className="w-12 h-12 text-blue-500" />,
      title: 'Intelligent Workforce Management',
      description: 'Optimize labor allocation with AI-driven analysis of skills, certifications, availability, and location.',
      benefits: [
        'Increased workforce productivity',
        'Reduced labor costs',
        'Enhanced employee satisfaction'
      ],
      techDetails: [
        'Advanced skills matching algorithm',
        'Real-time availability tracking',
        'Compliance monitoring system'
      ]
    },
    {
      icon: <Truck className="w-12 h-12 text-green-500" />,
      title: 'Equipment Utilization',
      description: 'Maximize machinery efficiency with smart scheduling and maintenance coordination.',
      benefits: [
        'Minimized equipment downtime',
        'Optimized maintenance scheduling',
        'Reduced equipment costs'
      ],
      techDetails: [
        'IoT-enabled tracking system',
        'Predictive maintenance alerts',
        'Usage pattern analysis'
      ]
    },
    {
      icon: <Map className="w-12 h-12 text-purple-500" />,
      title: 'Real-Time Resource Tracking',
      description: 'Monitor workforce and equipment deployment across all projects with GPS and IoT integration.',
      benefits: [
        'Enhanced resource visibility',
        'Improved security measures',
        'Dynamic resource reallocation'
      ],
      techDetails: [
        'GPS and IoT integration',
        'Geofencing capabilities',
        'Real-time status updates'
      ]
    },
    {
      icon: <LineChart className="w-12 h-12 text-indigo-500" />,
      title: 'Predictive Demand Forecasting',
      description: 'Anticipate resource needs using AI-powered analysis of historical data and project timelines.',
      benefits: [
        'Proactive resource planning',
        'Optimized cost management',
        'Reduced project delays'
      ],
      techDetails: [
        'Machine learning forecasting',
        'Scenario simulation engine',
        'Trend analysis system'
      ]
    }
  ];

  const stats = [
    {
      icon: <Target className="w-8 h-8 text-blue-500" />,
      value: "15",
      unit: "%",
      label: "Increase in Resource Utilization"
    },
    {
      icon: <Clock className="w-8 h-8 text-green-500" />,
      value: "20",
      unit: "%",
      label: "Reduction in Operational Costs"
    },
    {
      icon: <BarChart2 className="w-8 h-8 text-purple-500" />,
      value: "250",
      unit: "%",
      label: "ROI in First Year"
    }
  ];

  const aiCapabilities = [
    {
      icon: <Brain className="w-12 h-12 text-rose-500" />,
      title: 'Machine Learning',
      description: 'Optimize resource allocation through advanced learning algorithms.',
      capabilities: [
        'Pattern recognition',
        'Predictive analytics',
        'Automated optimization'
      ]
    },
    {
      icon: <Cpu className="w-12 h-12 text-amber-500" />,
      title: 'Neural Networks',
      description: 'Identify complex patterns in resource utilization.',
      capabilities: [
        'Usage pattern analysis',
        'Anomaly detection',
        'Efficiency optimization'
      ]
    },
    {
      icon: <Radio className="w-12 h-12 text-cyan-500" />,
      title: 'IoT Integration',
      description: 'Real-time monitoring and tracking of all resources.',
      capabilities: [
        'GPS tracking',
        'Equipment monitoring',
        'Status reporting'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>AI-Powered Resource Allocation | Construct Regulate</title>
        <meta name="description" content="Optimize your construction workforce and equipment distribution with AI-driven resource allocation. Maximize efficiency, reduce downtime, and ensure optimal resource utilization." />
        <meta name="keywords" content="resource allocation, construction management, AI automation, workforce management, equipment utilization, construction efficiency" />
        <meta property="og:title" content="AI-Powered Resource Allocation | Construct Regulate" />
        <meta property="og:description" content="Transform your construction resource management with AI automation. Optimize workforce and equipment allocation for maximum efficiency." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AI-Powered Resource Allocation | Construct Regulate" />
        <meta name="twitter:description" content="Transform your construction resource management with AI automation. Optimize workforce and equipment allocation for maximum efficiency." />
      </Helmet>

      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-purple-600 h-screen">
        <div className="absolute inset-0 bg-black/40" />
        <div className="relative container mx-auto px-4 h-full flex items-center">
          <motion.div 
            className="max-w-3xl text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Resource Allocation Automation
            </h1>
            <p className="text-xl mb-8 text-gray-200">
              Optimize your workforce and equipment distribution with AI-driven resource allocation. Maximize efficiency, reduce downtime, and ensure your resources are utilized to their fullest potential.
            </p>
            <div className="flex flex-wrap gap-4">
              <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                Schedule Demo
              </button>
              <button className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                Learn More
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="max-w-6xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-8">
              Revolutionize Resource Management with AI
            </h2>
            <p className="text-lg text-gray-600 text-center mb-16 max-w-3xl mx-auto">
              Traditional resource management often results in underutilized assets and inefficient scheduling. Our AI-driven system ensures optimal resource allocation across all your projects.
            </p>
            
            {/* Stats Section */}
            <div className="grid md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                  whileHover={{ y: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex justify-center mb-4">{stat.icon}</div>
                  <div className="text-4xl font-bold text-blue-900 mb-2">
                    {stat.value}<span className="text-blue-500">{stat.unit}</span>
                  </div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Key Features</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our AI-powered resource allocation system provides comprehensive features to optimize your workforce and equipment management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-white rounded-xl shadow-lg p-8"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="mb-6">{feature.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-600 mb-6">{feature.description}</p>
                
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Benefits</h4>
                    <ul className="space-y-3">
                      {feature.benefits.map((benefit, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-lg mb-3">Technical Details</h4>
                    <ul className="space-y-3">
                      {feature.techDetails.map((detail, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                          <span className="text-gray-700">{detail}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* AI Capabilities Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Advanced AI Capabilities</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Powered by cutting-edge artificial intelligence technologies that optimize resource allocation and management.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {aiCapabilities.map((capability, index) => (
              <motion.div
                key={capability.title}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5 }}
              >
                <div className="mb-6">{capability.icon}</div>
                <h3 className="text-2xl font-bold mb-4">{capability.title}</h3>
                <p className="text-gray-600 mb-6">{capability.description}</p>
                <ul className="space-y-3">
                  {capability.capabilities.map((item, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <ArrowRight className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Case Study Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto bg-white rounded-2xl p-12 shadow-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">Success Story: Large Infrastructure Project</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold text-xl mb-2">Challenge:</h3>
                <p className="text-gray-600">
                  A construction company managing multiple large-scale infrastructure projects faced difficulties in effectively allocating resources, leading to equipment idle time and labor inefficiencies.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Solution:</h3>
                <p className="text-gray-600">
                  We implemented AI-driven resource allocation system with real-time tracking and predictive forecasting capabilities.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-xl mb-2">Results:</h3>
                <ul className="space-y-2">
                <li className="flex items-start gap-2">
                    <CheckCircle className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">250% ROI achieved in the first year</span>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Implementation Process */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold mb-4">Implementation Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Our structured approach ensures a smooth transition to AI-powered resource allocation.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-6 gap-8">
            {[
              {
                step: "1",
                title: "Resource Audit",
                description: "Assess current processes and catalog all assets",
                icon: <ClipboardList className="w-8 h-8 text-blue-500" />
              },
              {
                step: "2",
                title: "System Design",
                description: "Configure AI algorithms for your needs",
                icon: <Settings className="w-8 h-8 text-green-500" />
              },
              {
                step: "3",
                title: "Data Integration",
                description: "Connect with existing systems and IoT devices",
                icon: <Radio className="w-8 h-8 text-purple-500" />
              },
              {
                step: "4",
                title: "Testing",
                description: "Validate performance with pilot program",
                icon: <Wrench className="w-8 h-8 text-indigo-500" />
              },
              {
                step: "5",
                title: "Deployment",
                description: "Roll out across all projects and sites",
                icon: <Building className="w-8 h-8 text-rose-500" />
              },
              {
                step: "6",
                title: "Optimization",
                description: "Monitor and refine for best results",
                icon: <LineChart className="w-8 h-8 text-amber-500" />
              }
            ].map((phase, index) => (
              <motion.div
                key={phase.step}
                className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-2xl p-8 shadow-lg text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mx-auto mb-6 shadow-md">
                  <span className="text-blue-600 font-bold text-xl">{phase.step}</span>
                </div>
                <div className="flex justify-center mb-4">{phase.icon}</div>
                <h3 className="text-xl font-bold mb-3">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Security Section */}
      <div className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold mb-4">Security & Compliance</h2>
              <p className="text-gray-600">
                We ensure the highest standards of security and compliance in managing your resource data.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  title: "Data Protection",
                  description: "End-to-end encryption for all resource data",
                  icon: <Lock className="w-8 h-8 text-blue-500" />
                },
                {
                  title: "Compliance",
                  description: "Adherence to industry regulations",
                  icon: <Shield className="w-8 h-8 text-green-500" />
                },
                {
                  title: "Access Control",
                  description: "Role-based permissions system",
                  icon: <AlertCircle className="w-8 h-8 text-purple-500" />
                }
              ].map((item, index) => (
                <motion.div
                  key={item.title}
                  className="bg-white rounded-xl shadow-lg p-6 text-center"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="flex justify-center mb-4">{item.icon}</div>
                  <h3 className="text-xl font-bold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "How does the AI system handle unexpected changes?",
                  answer: "Our AI system continuously monitors variables and can reallocate resources in real-time, providing recommendations based on current priorities and availability."
                },
                {
                  question: "Can the system accommodate union labor rules?",
                  answer: "Yes, the system is fully configurable to account for union agreements, labor laws, overtime restrictions, and other contractual obligations."
                },
                {
                  question: "What tracking technologies are required?",
                  answer: "Our system integrates with various tracking technologies including GPS devices, RFID tags, and IoT sensors, based on your existing infrastructure."
                },
                {
                  question: "Will implementation disrupt current operations?",
                  answer: "Our implementation process is designed to minimize disruption, with a phased approach that ensures smooth transition and continuous operations."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-xl p-6"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <h3 className="text-xl font-bold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-24">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-white"
          >
            <h2 className="text-4xl font-bold mb-6">Take Control of Your Resources</h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              Transform your resource management with AI automation. Maximize efficiency, reduce costs, and optimize operations across all your projects.
            </p>
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
              Schedule Your Free Consultation
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ResourceAllocation;
