import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const theme = {
  colors: {
    primary: '#0A2342',
    secondary: '#2CA6A4',
    accent: '#F46036',
    background: '#F7F7F7',
    text: '#333333',
    darkBackground: '#2F2F2F',
    darkText: '#E0E0E0',
  },
  fonts: {
    heading: "'Poppins', sans-serif",
    body: "'Inter', sans-serif",
  },
};

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [currentStyle, setCurrentStyle] = useState('Industrial Tech');

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
  };

  const toggleStyle = () => {
    setCurrentStyle(currentStyle === 'Construct Future' ? 'Industrial Tech' : 'Construct Future');
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode, currentStyle, setCurrentStyle }}>
      {children}
    </ThemeContext.Provider>
  );
};
